export const whatsappAudioFormat = "ogg";

export const acceptedAudioType = ["oga", "mp3", "wav", whatsappAudioFormat];

export const acceptedImgType = ["jpeg", "jpg", "png", "svg", "gif", "webp"];

export const acceptedMessageFiles =
  "image/jpeg,image/png,image/webp,audio/acc,audio/mp4,audio/mpeg,audio/amr,audio/ogg,video/mp4,video/3gp,text/plain,application/pdf,application/msword";

export const acceptedVideoType = ["webm", "mp4"];

export const csvURI = "data:text/csv;charset=utf-8,%EF%BB%BF";

export const textURI = "data:text/plain;charset=utf-8,%EF%BB%BF";

export const whatsappMimeType = "audio/ogg; codecs=opus";
