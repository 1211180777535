import {
  ActionOptModel,
  BaseProviderType,
  CampaignTypeOptModel,
  ChannelOptModel,
  ChannelTypeOptModel,
  FolderOptModel,
  HSMTemplateOptModel,
  OrderStateOptModel,
  PermissionProviderType,
  PhoneExternalOptModel,
  RoleOptModel,
  SubChannelTypeOptModel,
  TicketCellOptModel,
  TicketReasonOptModel,
  TicketStateOptModel,
  TicketSubReasonOptModel,
  UserAuditOptModel,
  UserOperatorOptModel,
  UserParentOptModel,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Errors } from "services/errors";
import * as ListService from "services/list";
import { aytyFormatError, sortBy } from "utils";

type ListContextType = {
  loading: boolean;
  actionOpts?: ActionOptModel[];
  campaignTypeOpts?: CampaignTypeOptModel[];
  channelOpts?: ChannelOptModel[];
  channelTypeOpts?: ChannelTypeOptModel[];
  folderOpts?: FolderOptModel[];
  hsmTemplateOpts?: HSMTemplateOptModel[];
  orderStateOpts?: OrderStateOptModel[];
  phoneExternalOpts?: PhoneExternalOptModel[];
  roleOpts?: RoleOptModel[];
  subChannelTypeOpts?: SubChannelTypeOptModel[];
  ticketCellOpts?: TicketCellOptModel[];
  ticketReasonOpts?: TicketReasonOptModel[];
  ticketStateOpts?: TicketStateOptModel[];
  ticketSubReasonOpts?: TicketSubReasonOptModel[];
  userAuditOpts?: UserAuditOptModel[];
  userOperatorOpts?: UserOperatorOptModel[];
  userParentList?: UserParentOptModel[];
  userParentOpts?: UserParentOptModel[];
  getUserParentOpts: () => Promise<void>;
};

const ListContext = createContext({} as ListContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [actionOpts, setActionOpts] = useState<ActionOptModel[]>();
  const [campaignTypeOpts, setCampaignTypeOpts] =
    useState<CampaignTypeOptModel[]>();
  const [channelOpts, setChannelOpts] = useState<ChannelOptModel[]>();
  const [channelTypeOpts, setChannelTypeOpts] =
    useState<ChannelTypeOptModel[]>();
  const [folderOpts, setFolderOpts] = useState<FolderOptModel[]>();
  const [hsmTemplateOpts, setHSMTemplateOpts] =
    useState<HSMTemplateOptModel[]>();
  const [orderStateOpts, setOrderStateOpts] = useState<OrderStateOptModel[]>();
  const [phoneExternalOpts, setPhoneExternalOpts] =
    useState<PhoneExternalOptModel[]>();
  const [roleOpts, setRoleOpts] = useState<RoleOptModel[]>();
  const [subChannelTypeOpts, setSubChannelTypeOpts] =
    useState<SubChannelTypeOptModel[]>();
  const [ticketCellOpts, setTicketCellOpts] = useState<TicketCellOptModel[]>();
  const [ticketReasonOpts, setTicketReasonOpts] =
    useState<TicketReasonOptModel[]>();
  const [ticketStateOpts, setTicketStateOpts] =
    useState<TicketStateOptModel[]>();
  const [ticketSubReasonOpts, setTicketSubReasonOpts] =
    useState<TicketSubReasonOptModel[]>();
  const [userAuditOpts, setUserAuditOpts] = useState<UserAuditOptModel[]>();
  const [userOperatorOpts, setUserOperatorOpts] =
    useState<UserOperatorOptModel[]>();
  const [userParentList, setUserParentList] = useState<UserParentOptModel[]>();
  const { user, pIdProject, projectOpts } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userParentOpts = useMemo(
    () => userParentList?.filter((u) => u.IdProject === pIdProject),
    [userParentList, pIdProject]
  );

  const getLists = useCallback(async () => {
    if (user?.hasAccessAttend)
      await Promise.all([
        ListService.getFolderOpts().then(({ data }) => {
          if (data.idReturnAPI > 0)
            setFolderOpts(sortBy(data.omniFolderList, "NmOmniFolder"));
          else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);

    if (user?.hasAccessRegister)
      await Promise.all([
        ListService.getActionOpts().then(({ data }) => {
          if (data.idReturnAPI > 0)
            setActionOpts(sortBy(data.actionList, "NmAction"));
          else warning({ description: t(aytyFormatError(data)) });
        }),
        ListService.getRoleOpts().then(({ data }) => {
          if (data.idReturnAPI > 0) {
            const current = data.roleList?.filter((r) => r.IsActive);
            setRoleOpts(sortBy(current, "NmRole"));
          } else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);

    if (user?.hasAccessRegister || user?.hasAccessReport)
      await Promise.all([
        ListService.getSubChannelTypeOpts().then(({ data }) => {
          if (data.idReturnAPI > 0) {
            const current = data.channelTypeList?.filter((c) => c.IsActive);
            setSubChannelTypeOpts(sortBy(current, "NmSubChannelType"));
          } else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);

    setLoading(false);
  }, [user, errorsResolver, warning, t]);

  useEffect(() => {
    getLists();
  }, [getLists]);

  const getUserParentOpts = useCallback(async () => {
    if (projectOpts?.length)
      await ListService.getUserParentOpts({
        pIdProject: projectOpts.map((p) => p.IdProject),
      }).then(({ data }) => {
        if (data.idReturnAPI > 0) {
          const current = data.userParentList?.filter((u) => u.IsActive);
          setUserParentList(sortBy(current, "NmUserParent"));
        } else warning({ description: t(aytyFormatError(data)) });
      });
  }, [projectOpts, warning, t]);

  const getProjectLists = useCallback(async () => {
    if (pIdProject) {
      if (user?.hasAccessAttend)
        await Promise.all([
          ListService.getPhoneExternalOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.telephoneExternalList?.filter(
                (t) => t.IsActive
              );
              setPhoneExternalOpts(sortBy(current, "NmTelephoneExternal"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
        ]).catch(errorsResolver.defaultError);

      if (user?.hasAccessAdmin || user?.hasAccessRegister)
        await Promise.all([
          ListService.getChannelOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.omniSourceList?.filter((o) => o.IsActive);
              setChannelOpts(sortBy(current, "NmOmniSource"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
        ]).catch(errorsResolver.defaultError);

      if (user?.hasAccessRegister)
        await Promise.all([
          ListService.getCampaignTypeOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.campaignTypeList?.filter((c) => c.IsActive);
              setCampaignTypeOpts(sortBy(current, "NmCampaignType"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
          ListService.getChannelTypeOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.channelTypeList?.filter((c) => c.IsActive);
              setChannelTypeOpts(sortBy(current, "NmChannelType"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
          ListService.getHSMTemplateOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0)
              setHSMTemplateOpts(
                sortBy(
                  data.messageOutboundTemplateList,
                  "NmMessageOutboundTemplate"
                )
              );
            else warning({ description: t(aytyFormatError(data)) });
          }),
        ]).catch(errorsResolver.defaultError);

      if (user?.hasAccessRegister && user.hasTicketModule)
        await Promise.all([
          ListService.getTicketCellOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.ticketCellList?.filter((t) => t.IsActive);
              setTicketCellOpts(sortBy(current, "NmTicketCell"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
          ListService.getTicketReasonOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.ticketReasonList?.filter((t) => t.IsActive);
              setTicketReasonOpts(sortBy(current, "NmTicketReason"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
          ListService.getTicketStateOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.ticketStateList?.filter((t) => t.IsActive);
              setTicketStateOpts(sortBy(current, "NmTicketState"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
          ListService.getTicketSubReasonOpts({ pIdProject }).then(
            ({ data }) => {
              if (data.idReturnAPI > 0) {
                const current = data.ticketSubReasonList?.filter(
                  (t) => t.IsActive
                );
                setTicketSubReasonOpts(sortBy(current, "NmTicketSubReason"));
              } else warning({ description: t(aytyFormatError(data)) });
            }
          ),
        ]).catch(errorsResolver.defaultError);

      if (user?.hasAccessReport || user?.hasAccessAudit)
        await Promise.all([
          ListService.getOrderStateOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.orderStateList?.filter((o) => o.IsActive);
              setOrderStateOpts(sortBy(current, "NmOrderState"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
        ]).catch(errorsResolver.defaultError);

      if (user?.hasAccessAudit)
        await Promise.all([
          ListService.getUserAuditOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.userAuditList?.filter((u) => u.IsActive);
              setUserAuditOpts(sortBy(current, "NmUser"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
          ListService.getUserOperatorOpts({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const current = data.userOperatorList?.filter((u) => u.IsActive);
              setUserOperatorOpts(sortBy(current, "NmUser"));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
        ]).catch(errorsResolver.defaultError);

      if (user) await getUserParentOpts().catch(errorsResolver.defaultError);
    }
    setLoading(false);
  }, [pIdProject, user, errorsResolver, getUserParentOpts, warning, t]);

  useEffect(() => {
    getProjectLists();
  }, [getProjectLists]);

  return (
    <ListContext.Provider
      value={{
        loading,
        actionOpts,
        campaignTypeOpts,
        channelOpts,
        channelTypeOpts,
        folderOpts,
        hsmTemplateOpts,
        orderStateOpts,
        phoneExternalOpts,
        roleOpts,
        subChannelTypeOpts,
        ticketCellOpts,
        ticketReasonOpts,
        ticketStateOpts,
        ticketSubReasonOpts,
        userAuditOpts,
        userOperatorOpts,
        userParentList,
        userParentOpts,
        getUserParentOpts,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export const ListProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useList = () => useContext(ListContext);
