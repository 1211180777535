// Models

import { BaseReponseType } from "models";

export class CampaignExportClass {
  IdCampaign: number;
  NmCampaign: string;
  IsActive: boolean;
  IdAttendGroupOmniWhatsapp: number;
  NmAttendGroupOmniWhatsapp: string;

  constructor(c: CampaignModel) {
    this.IdCampaign = c.IdCampaign;
    this.NmCampaign = c.NmCampaign;
    this.IsActive = c.IsActive;
    this.IdAttendGroupOmniWhatsapp = c.IdAttendGroupOmniWhatsapp;
    this.NmAttendGroupOmniWhatsapp = c.NmAttendGroupOmniWhatsapp;
  }
}

export type CampaignModel = {
  IdCampaign: number;
  NmCampaign: string;
  IsActive: boolean;
  IdAttendGroupOmniWhatsapp: number;
  NmAttendGroupOmniWhatsapp: string;
};

export class CampaignTypeExportClass {
  IdCampaignType: number;
  NmCampaignType: string;
  IsActive: boolean;
  IdCrmConfiguration: number;
  NmCrmConfiguration: string;

  constructor(c: CampaignTypeModel) {
    this.IdCampaignType = c.IdCampaignType;
    this.NmCampaignType = c.NmCampaignType;
    this.IsActive = c.IsActive;
    this.IdCrmConfiguration = c.IdCrmConfiguration;
    this.NmCrmConfiguration = c.NmCrmConfiguration;
  }
}

export type CampaignTypeModel = {
  IdCampaignType: number;
  NmCampaignType: string;
  IsActive: boolean;
  IdProject: number;
  IdCrmConfiguration: number;
  NmCrmConfiguration: string;
};

// Requests

export type GetCampaignsRequest = {
  pIdProject: number;
  pNmCampaign?: string;
};

export type GetCampaignTypesRequest = {
  pIdProject: number;
  pNmCampaignType?: string;
};

export type ManageCampaignRequest = {
  pIdProject: number;
  pIdCampaign: number;
  pNmCampaign: string;
  pIsActive: boolean;
  pIdAttendGroupOmniWhatsapp?: number;
};

export type ManageCampaignTypeRequest = {
  pIdProject: number;
  pIdCampaignType: number;
  pNmCampaignType: string;
  pIsActive: boolean;
  pIdCrmConfiguration: number;
};

// Responses

export type GetCampaignsResponse = BaseReponseType & {
  campaignList: CampaignModel[];
};

export type GetCampaignTypesResponse = BaseReponseType & {
  campaignTypeList: CampaignTypeModel[];
};

export type ManageCampaignResponse = BaseReponseType;

export type ManageCampaignTypeResponse = BaseReponseType;
