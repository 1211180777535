import { forwardRef, ReactNode, useMemo } from "react";
import Loading from "../loading";
import { ButtonstyleType, ColorStyleType, Container } from "./styles";

type ButtonType = ButtonstyleType & {
  children: ReactNode;
  loading?: boolean;
  icon?: ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
};

const Button = forwardRef<HTMLButtonElement, ButtonType>(
  ({ children, loading, icon, onClick, type = "button", ...args }, ref) => {
    const disabled = useMemo(() => loading || args.$disabled, [loading, args]);

    return (
      <Container
        {...args}
        ref={ref}
        type={type}
        disabled={disabled}
        onClick={() => !disabled && onClick?.()}
      >
        {loading ? <Loading /> : icon}
        {children}
      </Container>
    );
  }
);

export type { ColorStyleType as ButtonColorType };
export default Button;
