import { AxiosRequestConfig } from "axios";
import { formDataConfig } from "consts";
import {
  CancelHSMBatchRequest,
  CancelHSMBatchResponse,
  GetHSMBatchsRequest,
  GetHSMBatchsResponse,
  GetHSMTemplatesRequest,
  GetHSMTemplatesResponse,
  ManageHSMBatchRequest,
  ManageHSMBatchResponse,
  ManageHSMTemplateRequest,
  ManageHSMTemplateResponse,
  SendHSMTestRequest,
  SendHSMTestResponse,
} from "models";
import service from "services";

// Manage hsm
export const cancelHSMBatch = async (
  params: CancelHSMBatchRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<CancelHSMBatchResponse>("HSM/CancelHSMBatch", {
    ...config,
    params,
  });

export const getHSMBatchs = async (
  params: GetHSMBatchsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetHSMBatchsResponse>("HSM/QueryHSMBatch", {
    ...config,
    params,
  });

export const getHSMTemplates = async (
  params: GetHSMTemplatesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetHSMTemplatesResponse>(
    "HSM/QueryMessageOutboundTemplate",
    { ...config, params }
  );

export const manageHSMBatch = async (
  data: FormData,
  params: ManageHSMBatchRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<ManageHSMBatchResponse>("HSM/ManagerHSMBatch", data, {
    ...config,
    ...formDataConfig,
    params,
  });

export const manageHSMTemplate = async (
  params: ManageHSMTemplateRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageHSMTemplateResponse>(
    "HSM/ManagerMessageOutboundTemplate",
    { ...config, params }
  );

export const sendHSMTest = async (
  params: SendHSMTestRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SendHSMTestResponse>("HSM/SendTestHSM", {
    ...config,
    params,
  });
