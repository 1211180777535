import { useTranslation } from "react-i18next";
import { Container } from "./styles";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h2>{t("pageNotFound")}</h2>
    </Container>
  );
};

export default NotFound;
