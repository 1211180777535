// Models

import { BaseReponseType } from "models";

export class StatusByCampaignExportClass {
  IdStatus: number;
  NmStatus: string;
  IdStatusGroup: number;
  NmStatusGroup: string;
  IdCampaignType?: number;
  IsAvailableCampaignType: boolean;

  constructor(s: StatusByCampaignModel) {
    this.IdStatus = s.IdStatus;
    this.NmStatus = s.NmStatus;
    this.IdStatusGroup = s.IdStatusGroup;
    this.NmStatusGroup = s.NmStatusGroup;
    this.IdCampaignType = s.IdCampaignType;
    this.IsAvailableCampaignType = s.IsAvailableCampaignType;
  }
}

export type StatusByCampaignModel = {
  IdStatus: number;
  NmStatus: string;
  IdStatusGroup: number;
  NmStatusGroup: string;
  IdProject: number;
  IdCampaignType?: number;
  IsAvailableCampaignType: boolean;
};

export class StatusGroupExportClass {
  IdStatusGroup: number;
  NmStatusGroup: string;
  IsActive: boolean;

  constructor(s: StatusGroupModel) {
    this.IdStatusGroup = s.IdStatusGroup;
    this.NmStatusGroup = s.NmStatusGroup;
    this.IsActive = s.IsActive;
  }
}

export type StatusGroupModel = {
  IdStatusGroup: number;
  NmStatusGroup: string;
  IdProject: string;
  IsActive: boolean;
};

export class StatusExportClass {
  IdStatus: number;
  NmStatus: string;
  IsActive: boolean;
  IdStatusGroup: number;
  NmStatusGroup: string;
  CodeStatusClient: string;
  CodeStatusCrmToPredictive: string;
  IdStatusTypeDs: number;
  NmStatusTypeDs: string;
  IdProject: number;
  IsAvailableVoice: boolean;
  IsAvailableChat: boolean;
  IsAvailableApproach: boolean;
  IsAvailableRedial: boolean;
  IsAvailableSchedule: boolean;
  IsApproach: boolean;
  IsOrder: boolean;
  MustValidateBindListFinishOmni: boolean;

  constructor(s: StatusModel) {
    this.IdStatus = s.IdStatus;
    this.NmStatus = s.NmStatus;
    this.IsActive = s.IsActive;
    this.IdStatusGroup = s.IdStatusGroup;
    this.NmStatusGroup = s.NmStatusGroup;
    this.CodeStatusClient = s.CodeStatusClient;
    this.CodeStatusCrmToPredictive = s.CodeStatusCrmToPredictive;
    this.IdStatusTypeDs = s.IdStatusTypeDs;
    this.NmStatusTypeDs = s.NmStatusTypeDs;
    this.IdProject = s.IdProject;
    this.IsAvailableVoice = s.IsAvailableVoice;
    this.IsAvailableChat = s.IsAvailableChat;
    this.IsAvailableApproach = s.IsAvailableApproach;
    this.IsAvailableRedial = s.IsAvailableRedial;
    this.IsAvailableSchedule = s.IsAvailableSchedule;
    this.IsApproach = s.IsApproach;
    this.IsOrder = s.IsOrder;
    this.MustValidateBindListFinishOmni = s.MustValidateBindListFinishOmni;
  }
}

export type StatusModel = {
  IdStatus: number;
  NmStatus: string;
  IsActive: boolean;
  IdStatusGroup: number;
  NmStatusGroup: string;
  CodeStatusClient: string;
  CodeStatusCrmToPredictive: string;
  IdStatusTypeDs: number;
  NmStatusTypeDs: string;
  IdProject: number;
  IsAvailableVoice: boolean;
  IsAvailableChat: boolean;
  IsAvailableApproach: boolean;
  IsAvailableRedial: boolean;
  IsAvailableSchedule: boolean;
  IsApproach: boolean;
  IsOrder: boolean;
  MustValidateBindListFinishOmni: boolean;
};

// Requests

export type GetStatusByCampaignRequest = {
  pIdProject: number;
  pIdCampaingType: number;
};

export type GetStatusGroupRequest = {
  pIdProject: number;
  pNmStatusGroup?: string;
};

export type GetStatusRequest = {
  pIdProject: number;
  pNmStatus?: string;
};

export type ManageStatusByCampaignRequest = {
  pIdProject: number;
  pIdCampaingType: number;
  pIdStatus: number;
  pIsAvailableCampaignType: boolean;
};

export type ManageStatusGroupRequest = {
  pIdProject: number;
  pNmStatusGroup: string;
  pIdStatusGroup: number;
  pIsActive: boolean;
};

export type ManageStatusRequest = {
  pIdProject: number;
  pIdStatus: number;
  pNmStatus: string;
  pIsActive: boolean;
  pIdStatusGroup: number;
  pCodeStatusClient: string;
  pCodeStatusCrmToPredictive: string;
  pIdStatusTypeDs: number;
  pIsAvailableVoice: boolean;
  pIsAvailableChat: boolean;
  pIsAvailableApproach: boolean;
  pIsAvailableRedial: boolean;
  pIsAvailableSchedule: boolean;
  pIsApproach: boolean;
  pIsOrder: boolean;
  pMustValidateBindListFinishOmni: boolean;
};

// Responses

export type GetStatusByCampaignResponse = BaseReponseType & {
  statusSystemContextList: StatusByCampaignModel[];
};

export type GetStatusGroupResponse = BaseReponseType & {
  statusGroupList: StatusGroupModel[];
};

export type GetStatusResponse = BaseReponseType & {
  statusList: StatusModel[];
};

export type ManageStatusByCampaignResponse = BaseReponseType;

export type ManageStatusGroupResponse = BaseReponseType & {
  idStatusGroup: number;
};

export type ManageStatusResponse = BaseReponseType & {
  idStatus: number;
};
