import Toast, { ToastOptionTypes, ToastType } from "components/toast";
import { useMap } from "hooks";
import { BaseProviderType } from "models";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generateUID } from "utils";
import { Container, ToastProviderStyleType } from "./styles";

type CreateToastParams = Omit<ToastType, "removeMe" | "fixed">;

export type CreateToastType = (args: CreateToastParams) => void;

type ToastContextType = {
  success: CreateToastType;
  error: CreateToastType;
  warning: CreateToastType;
  info: CreateToastType;
  fixed: (type: ToastOptionTypes, args: CreateToastParams) => void;
  removeFixed: () => void;
};

type ToastProviderType = BaseProviderType & ToastProviderStyleType;

const fixedId = generateUID();

const ToastContext = createContext<ToastContextType>({} as ToastContextType);

export const ToastProvider = ({ children, ...args }: ToastProviderType) => {
  const [toasts, { add, remove }] = useMap<string, ToastType>();
  const { t } = useTranslation();

  const createToast = useMemo(
    () =>
      function (this: ToastOptionTypes, args: CreateToastParams): void {
        const id = generateUID();

        add(id, {
          title: args.title ?? t(this),
          description: args.description,
          action: args.action,
          type: this,
          removeMe: () => remove(id),
        });
      },
    [t, add, remove]
  );

  const success = useMemo(() => createToast.bind("success"), [createToast]);
  const error = useMemo(() => createToast.bind("error"), [createToast]);
  const warning = useMemo(() => createToast.bind("warning"), [createToast]);
  const info = useMemo(() => createToast.bind("info"), [createToast]);

  const removeFixed = useCallback(() => {
    remove(fixedId);
  }, [remove]);

  const fixed = useCallback(
    (type: ToastOptionTypes, args: CreateToastParams) => {
      add(fixedId, {
        title: args.title ?? t(type),
        description: args.description,
        action: args.action,
        fixed: true,
        type,
        removeMe: removeFixed,
      });
    },
    [t, add, removeFixed]
  );

  return (
    <ToastContext.Provider
      value={{ success, error, warning, info, fixed, removeFixed }}
    >
      <Container {...args}>
        {Array.from(toasts ?? [])?.map(([id, props]) => (
          <Toast {...props} key={id} />
        ))}
      </Container>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
