import { AxiosRequestConfig } from "axios";
import {
  DeleteFieldRequest,
  DeleteFieldResponse,
  GetFieldsByFormRequest,
  GetFieldsByFormResponse,
  GetFieldsRequest,
  GetFieldsResponse,
  GetFormsRequest,
  GetFormsResponse,
  ManageFieldRequest,
  ManageFieldResponse,
  ManageFormRequest,
  ManageFormResponse,
} from "models";
import service from "services";

// Manage field
export const deleteField = async (
  params: DeleteFieldRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<DeleteFieldResponse>("Bind/DeleteBind", {
    ...config,
    params,
  });

export const getFields = async (
  params: GetFieldsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFieldsResponse>("Bind/QueryBind", {
    ...config,
    params,
  });

export const getFieldsByForm = async (
  params: GetFieldsByFormRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFieldsByFormResponse>(
    "CrmConfiguration/GetBindListCustomer",
    { ...config, params }
  );

export const manageField = async (
  params: ManageFieldRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageFieldResponse>("Bind/ManagerBind", {
    ...config,
    params,
  });

// Manage form
export const getForms = async (
  params: GetFormsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFormsResponse>(
    "CrmConfiguration/QueryCrmConfiguration",
    { ...config, params }
  );

export const manageForm = async (
  params: ManageFormRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageFormResponse>(
    "CrmConfiguration/ManagerCrmConfiguration",
    { ...config, params }
  );
