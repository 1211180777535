import { useMap } from "hooks";
import { BaseProviderType, ChannelModel, PermissionProviderType } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as ChannelService from "services/channel";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, ChannelModel> | undefined;

type ChannelContextType = {
  loading: boolean;
  channels?: ChannelModel[];
  activeChannels?: ChannelModel[];
  getChannels: () => Promise<void>;
  addChannel: (key: number, value: ChannelModel) => Promise<UndefinedMap>;
  editChannel: (key: number, value: ChannelModel) => Promise<UndefinedMap>;
  removeChannel: (key: number) => Promise<UndefinedMap>;
};

const ChannelContext = createContext({} as ChannelContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, ChannelModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const channels = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeChannels = useMemo(
    () => channels?.filter((c) => c.IsActive),
    [channels]
  );

  const getChannels = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await ChannelService.getChannels({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.omniSourceList.map((c) => [c.IdOmniSource, c])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getChannels();
  }, [getChannels]);

  return (
    <ChannelContext.Provider
      value={{
        channels,
        activeChannels,
        loading,
        getChannels,
        addChannel: add,
        editChannel: edit,
        removeChannel: remove,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export const ChannelProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useChannel = () => useContext(ChannelContext);
