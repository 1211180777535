export const allFormsKey = process.env.REACT_APP_ALL_FORMS_KEY || "allForms";

export const allIntegrationsKey =
  process.env.REACT_APP_ALL_INTEGRATIONS_KEY || "allIntegrations";

export const allHistoriesKey =
  process.env.REACT_APP_ALL_HISTORIES_KEY || "allHistories";

export const allMsgsKey = process.env.REACT_APP_ALL_MSGS_KEY || "allMsgs";

export const allTicketsKey =
  process.env.REACT_APP_ALL_TICKETS_KEY || "allTickets";

export const allOrdersKey = process.env.REACT_APP_ALL_ORDERS_KEY || "allOrders";

export const allQuestionsKey =
  process.env.REACT_APP_ALL_QUESTIONS_KEY || "allQuestions";

export const tokenKey = process.env.REACT_APP_TOKEN_KEY || "token";

export const ls = localStorage;

export const menuKey = process.env.REACT_APP_MENU_KEY || "isCollapsed";

export const pathDivider =
  process.env.REACT_APP_PATH_DIVIDER || "CRMCloudWebAPI";

export const projectKey = process.env.REACT_APP_PROJECT_KEY || "projectId";

export const projectVersion =
  process.env.REACT_APP_PROJECT_VERSION || "x.x.x.xxx";

export const sortByKey = process.env.REACT_APP_SORT_BY_KEY || "sortBy";

export const ss = sessionStorage;

export const statusKey = process.env.REACT_APP_STATUS_KEY || "pauseStatus";

export const videoBgKey = process.env.REACT_APP_VIDEO_BG_KEY || "videoBg";
