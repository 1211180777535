// Models

import { BaseReponseType } from "models";

export class ProjectExportClass {
  IdProject: number;
  DeProject: string;
  IsActive: boolean;
  DeUrlWebPhone: string;
  DeUrlDialerApi: string;
  MustFilterStatusByCampaignType: boolean;

  constructor(p: ProjectModel) {
    this.IdProject = p.IdProject;
    this.DeProject = p.DeProject;
    this.IsActive = p.IsActive;
    this.DeUrlWebPhone = p.DeUrlWebPhone;
    this.DeUrlDialerApi = p.DeUrlDialerApi;
    this.MustFilterStatusByCampaignType = p.MustFilterStatusByCampaignType;
  }
}

export type ProjectModel = {
  IdProject: number;
  DeProject: string;
  IsActive: boolean;
  DeUrlWebPhone: string;
  DeUrlDialerApi: string;
  MustFilterStatusByCampaignType: boolean;
};

// Requests

export type ManageProjectRequest = {
  pIdProject: number;
  pDeProject: string;
  pIsActive: boolean;
  pDeUrlWebPhone: string;
  pDeUrlDialerApi: string;
  pMustFilterStatusByCampaignType: boolean;
};

// Responses

export type GetProjectsResponse = BaseReponseType & {
  projectList: ProjectModel[];
};

export type ManageProjectResponse = BaseReponseType;
