import { useMap } from "hooks";
import { BaseProviderType, FAQModel, PermissionProviderType } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as FAQService from "services/faq";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, FAQModel> | undefined;

type FAQContextType = {
  loading: boolean;
  FAQs?: FAQModel[];
  activeFAQs?: FAQModel[];
  getFAQs: () => Promise<void>;
  addFAQ: (key: number, value: FAQModel) => Promise<UndefinedMap>;
  editFAQ: (key: number, value: FAQModel) => Promise<UndefinedMap>;
  removeFAQ: (key: number) => Promise<UndefinedMap>;
};

const FAQContext = createContext({} as FAQContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, FAQModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const FAQs = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeFAQs = useMemo(() => FAQs?.filter((c) => c.IsActive), [FAQs]);

  const getFAQs = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await FAQService.getFAQs({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.omniFAQList.map((f) => [f.IdOmniFAQ, f])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getFAQs();
  }, [getFAQs]);

  return (
    <FAQContext.Provider
      value={{
        FAQs,
        activeFAQs,
        loading,
        getFAQs,
        addFAQ: add,
        editFAQ: edit,
        removeFAQ: remove,
      }}
    >
      {children}
    </FAQContext.Provider>
  );
};

export const FAQProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useFAQ = () => useContext(FAQContext);
