import { LoadingContainer, LoadingContent, LoadingStyleType } from "./styles";

const Loading = ({ ...args }: LoadingStyleType) => {
  return (
    <LoadingContainer {...args}>
      <LoadingContent />
    </LoadingContainer>
  );
};

export default Loading;
