// Models

import { BaseReponseType } from "models";

export class HSMBatchExportClass {
  IdHsmBatch: number;
  NmHsmBatch: string;
  DeProject: string;
  IsActive: boolean;
  DtRegister: string;
  NmFile: string;
  IdMessageOutboundTemplate: number;
  NmMessageOutboundTemplate: string;
  DtSchedule: string;
  WasProcessed: boolean;
  DeResult: string;
  HasError: boolean;
  QtRegister: number;
  QtSent: number;
  QtDelivered: number;
  QtRead: number;
  QtAnswered: number;
  QtNotAnswered: number;
  QtFailed: number;
  QtValidPhone: number;
  QtInvalidPhone: number;
  QtUndelivered: number;
  QtInternalFailed: number;
  QtBspFailed: number;

  constructor(h: HSMBatchModel) {
    this.IdHsmBatch = h.IdHsmBatch;
    this.NmHsmBatch = h.NmHsmBatch;
    this.DeProject = h.DeProject;
    this.IsActive = h.IsActive;
    this.DtRegister = h.DtRegister;
    this.NmFile = h.NmFile;
    this.IdMessageOutboundTemplate = h.IdMessageOutboundTemplate;
    this.NmMessageOutboundTemplate = h.NmMessageOutboundTemplate;
    this.DtSchedule = h.DtSchedule;
    this.WasProcessed = h.WasProcessed;
    this.DeResult = h.DeResult;
    this.HasError = h.HasError;
    this.QtRegister = h.QtRegister;
    this.QtSent = h.QtSent;
    this.QtDelivered = h.QtDelivered;
    this.QtRead = h.QtRead;
    this.QtAnswered = h.QtAnswered;
    this.QtNotAnswered = h.QtNotAnswered;
    this.QtFailed = h.QtFailed;
    this.QtValidPhone = h.QtValidPhone;
    this.QtInvalidPhone = h.QtInvalidPhone;
    this.QtUndelivered = h.QtUndelivered;
    this.QtInternalFailed = h.QtInternalFailed;
    this.QtBspFailed = h.QtBspFailed;
  }
}

export type HSMBatchModel = {
  IdHsmBatch: number;
  NmHsmBatch: string;
  IdProject: number;
  DeProject: string;
  IsActive: boolean;
  DtRegister: string;
  NmFile: string;
  IdMessageOutboundTemplate: number;
  NmMessageOutboundTemplate: string;
  DtSchedule: string;
  WasProcessed: boolean;
  DeResult: string;
  HasError: boolean;
  QtRegister: number;
  QtSent: number;
  QtDelivered: number;
  QtRead: number;
  QtAnswered: number;
  QtNotAnswered: number;
  QtFailed: number;
  QtValidPhone: number;
  QtInvalidPhone: number;
  QtUndelivered: number;
  QtInternalFailed: number;
  QtBspFailed: number;
};

export class HSMTemplateExportClass {
  IdMessageOutboundTemplate: number;
  IdOmniSource: number;
  NmOmniSource: string;
  NmMessageOutboundTemplate: string;
  DeMessageOutboundTemplate: string;
  CodeNamespace: string;
  DeVariables: string;
  CodeSocialMediaSource: string;
  IsActive: boolean;
  DeUrlCallbackResponses: string;

  constructor(h: HSMTemplateModel) {
    this.IdMessageOutboundTemplate = h.IdMessageOutboundTemplate;
    this.IdOmniSource = h.IdOmniSource;
    this.NmOmniSource = h.NmOmniSource;
    this.NmMessageOutboundTemplate = h.NmMessageOutboundTemplate;
    this.DeMessageOutboundTemplate = h.DeMessageOutboundTemplate;
    this.CodeNamespace = h.CodeNamespace;
    this.DeVariables = h.DeVariables;
    this.CodeSocialMediaSource = h.CodeSocialMediaSource;
    this.IsActive = h.IsActive;
    this.DeUrlCallbackResponses = h.DeUrlCallbackResponses;
  }
}

export type HSMTemplateModel = {
  IdMessageOutboundTemplate: number;
  IdOmniSource: number;
  NmOmniSource: string;
  NmMessageOutboundTemplate: string;
  DeMessageOutboundTemplate: string;
  CodeNamespace: string;
  DeVariables: string;
  CodeSocialMediaSource: string;
  IsActive: boolean;
  DeUrlCallbackResponses: string;
};

// Requests

export type CancelHSMBatchRequest = {
  pIdProject: number;
  pIdHsmBatch: number;
};

export type GetHSMBatchsRequest = {
  pIdProject: number;
  pNmHsmBatch: string;
  pDtStart: string;
  pDtFinish: string;
};

export type GetHSMTemplatesRequest = {
  pIdProject: number;
};

export type ManageHSMBatchRequest = {
  pIdProject: number;
  pIdHsmBatch: number;
  pNmHsmBatch: string;
  pIsActive: boolean;
  pNmFile: string;
  pIdMessageOutboundTemplate: number;
  pDtSchedule: string;
};

export type ManageHSMTemplateRequest = {
  pIdMessageOutboundTemplate: number;
  pIdOmniSource: number;
  pNmMessageOutboundTemplate: string;
  pDeMessageOutboundTemplate: string;
  pCodeNamespace: string;
  pIdProject: number;
  pDeUrlCallBackResponses: string;
  pIsActive: boolean;
};

export type SendHSMTestRequest = {
  pNuPhoneWhatsApp: string;
  pIdMessageOutboundTemplate: number;
};

// Responses

export type CancelHSMBatchResponse = BaseReponseType;

export type GetHSMBatchsResponse = BaseReponseType & {
  hsmBatchList: HSMBatchModel[];
};

export type GetHSMTemplatesResponse = BaseReponseType & {
  messageOutboundTemplateList: HSMTemplateModel[];
};

export type ManageHSMBatchResponse = BaseReponseType & {
  idHsmBatch: number;
};

export type ManageHSMTemplateResponse = BaseReponseType;

export type SendHSMTestResponse = BaseReponseType;
