import { useCallback, useMemo, useState } from "react";
import { sortBy } from "utils";

export const useSort = <T extends any>(list: T[] = []) => {
  const [sort, setSort] = useState<keyof T>();
  const [isDesc, setIsDesc] = useState(false);

  const sortedList = useMemo(() => {
    if (sort) return [...sortBy(list, sort, isDesc)];
    return [...list];
  }, [list, sort, isDesc]);

  const sortedBy = useCallback(
    (key: keyof T) => () => {
      setSort(key);
      setIsDesc(!isDesc && key === sort);
    },
    [sort, isDesc]
  );

  return { sortedList, sortedBy } as const;
};
