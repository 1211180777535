import { useMap } from "hooks";
import { BaseProviderType, PermissionProviderType, UserModel } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as UserService from "services/user";
import { sortBy } from "utils";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, UserModel> | undefined;

type UserContextType = {
  loading: boolean;
  users?: UserModel[];
  activeUsers?: UserModel[];
  getUsers: () => Promise<void>;
  addUser: (key: number, value: UserModel) => Promise<UndefinedMap>;
  editUser: (key: number, value: UserModel) => Promise<UndefinedMap>;
  removeUser: (key: number) => Promise<UndefinedMap>;
};

const UserContext = createContext({} as UserContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, UserModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const users = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeUsers = useMemo(() => users?.filter((u) => u.IsActive), [users]);

  const getUsers = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await UserService.getUsers({
        pIdProject: [pIdProject],
      })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(
              new Map(sortBy(data.userList, "NmUser").map((u) => [u.IdUser, u]))
            );
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, warning, t, set]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <UserContext.Provider
      value={{
        loading,
        users,
        activeUsers,
        getUsers,
        addUser: add,
        editUser: edit,
        removeUser: remove,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useUser = () => useContext(UserContext);
