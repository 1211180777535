import Loading from "components/loading";
import { forwardRef, ReactNode } from "react";
import { Container, IconButtonstyleType } from "./styles";

type IconButtonType = IconButtonstyleType & {
  icon: ReactNode;
  name?: string;
  $loading?: boolean;
  onClick?: () => void;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonType>(
  ({ icon, name, onClick, $loading = false, $disabled, ...args }, ref) => {
    return (
      <Container
        ref={ref}
        title={name}
        onClick={() => !$disabled && onClick?.()}
        $disabled={$disabled}
        {...args}
      >
        {$loading ? <Loading /> : icon}
      </Container>
    );
  }
);

export default IconButton;
