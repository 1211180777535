import { AxiosRequestConfig } from "axios";
import {
  GetActionOptsResponse,
  GetCampaignTypeOptsRequest,
  GetCampaignTypeOptsResponse,
  GetChannelOptsRequest,
  GetChannelOptsResponse,
  GetChannelTypeOptsRequest,
  GetChannelTypeOptsResponse,
  GetContextOptsResponse,
  GetControlColorOptsResponse,
  GetControlTypeOptsResponse,
  GetDBTypeOptsResponse,
  GetFieldTemplateOptsResponse,
  GetFolderOptsRequest,
  GetFolderOptsResponse,
  GetHSMTemplateOptsRequest,
  GetHSMTemplateOptsResponse,
  GetMaskOptsResponse,
  GetOrderStateOptsRequest,
  GetOrderStateOptsResponse,
  GetPhoneExternalOptsRequest,
  GetPhoneExternalOptsResponse,
  GetProjectOptsRequest,
  GetProjectOptsResponse,
  GetQueueOptsRequest,
  GetQueueOptsResponse,
  GetRoleOptsResponse,
  GetStatusApproachingOptsRequest,
  GetStatusApproachingOptsResponse,
  GetStatusGroupApproachingOptsRequest,
  GetStatusGroupApproachingOptsResponse,
  GetStatusRedialOptsRequest,
  GetStatusRedialOptsResponse,
  GetStatusScheduleOptsRequest,
  GetStatusScheduleOptsResponse,
  GetStatusTypeOptsResponse,
  GetSubChannelTypeOptsResponse,
  GetTemplateOptsRequest,
  GetTemplateOptsResponse,
  GetTicketCellOptsRequest,
  GetTicketCellOptsResponse,
  GetTicketReasonOptsRequest,
  GetTicketReasonOptsResponse,
  GetTicketStateOptsRequest,
  GetTicketStateOptsResponse,
  GetTicketSubReasonOptsRequest,
  GetTicketSubReasonOptsResponse,
  GetTransferQueueOptsRequest,
  GetTransferQueueOptsResponse,
  GetTransferUserOptsRequest,
  GetTransferUserOptsResponse,
  GetUserAuditOptsRequest,
  GetUserAuditOptsResponse,
  GetUserOperatorOptsRequest,
  GetUserOperatorOptsResponse,
  GetUserParentOptsRequest,
  GetUserParentOptsResponse,
} from "models";
import service from "services";

// Lists
export const getActionOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetActionOptsResponse>("AttendGroup/GetActionList", config);

export const getCampaignTypeOpts = async (
  params: GetCampaignTypeOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetCampaignTypeOptsResponse>(
    "CampaignType/GetCampaignTypeList",
    { ...config, params }
  );

export const getChannelOpts = async (
  params: GetChannelOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetChannelOptsResponse>("OmniSource/GetOmniSourceList", {
    ...config,
    params,
  });

export const getChannelTypeOpts = async (
  params: GetChannelTypeOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetChannelTypeOptsResponse>(
    "ChannelType/GetChannelTypeList",
    { ...config, params }
  );

export const getContextOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetContextOptsResponse>("Bind/GetContextList", config);

export const getControlColorOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetControlColorOptsResponse>(
    "Bind/GetControlColorList",
    config
  );

export const getControlTypeOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetControlTypeOptsResponse>(
    "Bind/GetControlTypeList",
    config
  );

export const getDBTypeOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetDBTypeOptsResponse>("Bind/GetDbTypeList", config);

export const getFieldTemplateOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetFieldTemplateOptsResponse>(
    "Bind/GetBindTemplateList",
    config
  );

export const getFolderOpts = async (
  params?: GetFolderOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFolderOptsResponse>("Omni/GetOmniFolder", {
    ...config,
    params,
  });

export const getHSMTemplateOpts = async (
  params?: GetHSMTemplateOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetHSMTemplateOptsResponse>(
    "HSM/GetMessageOutboundTemplateList",
    { ...config, params }
  );

export const getMaskOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetMaskOptsResponse>("Bind/GetMaskList", config);

export const getOrderStateOpts = async (
  params: GetOrderStateOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetOrderStateOptsResponse>("Order/GetOrderStateList", {
    ...config,
    params,
  });

export const getProjectOpts = async (
  params: GetProjectOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetProjectOptsResponse>("Project/GetProjectList", {
    ...config,
    params,
  });

export const getQueueOpts = async (
  params: GetQueueOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetQueueOptsResponse>("AttendGroup/GetAttendGroupList", {
    ...config,
    params,
    paramsSerializer: { indexes: null },
  });

export const getPhoneExternalOpts = async (
  params: GetPhoneExternalOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetPhoneExternalOptsResponse>(
    "Omni/GetTelephoneExternalList",
    { ...config, params }
  );

export const getRoleOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetRoleOptsResponse>("Role/GetRoleList", config);

export const getSubChannelTypeOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetSubChannelTypeOptsResponse>(
    "ChannelType/GetSubChannelTypeList",
    config
  );

export const getStatusApproachingOpts = async (
  params: GetStatusApproachingOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusApproachingOptsResponse>(
    "Omni/GetStatusListApproaching",
    { ...config, params }
  );

export const getStatusGroupApproachingOpts = async (
  params: GetStatusGroupApproachingOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusGroupApproachingOptsResponse>(
    "Omni/GetStatusGroupListApproaching",
    { ...config, params }
  );

export const getStatusRedialOpts = async (
  params: GetStatusRedialOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusRedialOptsResponse>("Omni/GetStatusListRedial", {
    ...config,
    params,
  });

export const getStatusScheduleOpts = async (
  params: GetStatusScheduleOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusScheduleOptsResponse>(
    "Omni/GetStatusListSchedule",
    { ...config, params }
  );

export const getStatusTypeOpts = async (config?: AxiosRequestConfig) =>
  await service.get<GetStatusTypeOptsResponse>(
    "Status/GetStatusTypeDsList",
    config
  );

export const getTemplateOpts = async (
  params: GetTemplateOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTemplateOptsResponse>(
    "Omni/GetMessageOutboundTemplateList",
    { ...config, params }
  );

export const getTicketCellOpts = async (
  params: GetTicketCellOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTicketCellOptsResponse>("Ticket/GetTicketCellList", {
    ...config,
    params,
  });

export const getTicketReasonOpts = async (
  params: GetTicketReasonOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTicketReasonOptsResponse>("Ticket/GetTicketReasonList", {
    ...config,
    params,
  });

export const getTicketStateOpts = async (
  params: GetTicketStateOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTicketStateOptsResponse>("Ticket/GetTicketStateList", {
    ...config,
    params,
  });

export const getTicketSubReasonOpts = async (
  params: GetTicketSubReasonOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTicketSubReasonOptsResponse>(
    "Ticket/GetTicketSubReasonList",
    { ...config, params }
  );

export const getTransferQueueOpts = async (
  params: GetTransferQueueOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTransferQueueOptsResponse>(
    "AttendGroup/GetAttendGroupTransferList",
    { ...config, params }
  );

export const getTransferUserOpts = async (
  params: GetTransferUserOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTransferUserOptsResponse>("User/GetUserTransferList", {
    ...config,
    params,
  });

export const getUserAuditOpts = async (
  params: GetUserAuditOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetUserAuditOptsResponse>("User/GetUserAuditList", {
    ...config,
    params,
  });

export const getUserOperatorOpts = async (
  params: GetUserOperatorOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetUserOperatorOptsResponse>("User/GetUserOperatorList", {
    ...config,
    params,
  });

export const getUserParentOpts = async (
  params: GetUserParentOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetUserParentOptsResponse>("User/GetUserParentList", {
    ...config,
    params,
    paramsSerializer: { indexes: null },
  });
