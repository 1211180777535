import { useMap } from "hooks";
import {
  BaseProviderType,
  PermissionModel,
  PermissionProviderType,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as PermissionService from "services/permission";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, PermissionModel> | undefined;

type PermissionContextType = {
  loading: boolean;
  permissions?: PermissionModel[];
  getPermissions: () => Promise<void>;
  addPermission: (key: number, value: PermissionModel) => Promise<UndefinedMap>;
  editPermission: (
    key: number,
    value: PermissionModel
  ) => Promise<UndefinedMap>;
  removePermission: (key: number) => Promise<UndefinedMap>;
};

const PermissionContext = createContext({} as PermissionContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, PermissionModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const permissions = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const getPermissions = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await PermissionService.getPermissions({
        pIdProject,
      })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.permissionList.map((c) => [c.IdPermission, c])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        loading,
        getPermissions,
        addPermission: add,
        editPermission: edit,
        removePermission: remove,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const PermissionProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const usePermission = () => useContext(PermissionContext);
