import { ReactNode } from "react";
import { AvatarImage, AvatarStyleType, Container } from "./styles";

export type AvatarType = AvatarStyleType & {
  src?: string | any;
  icon?: ReactNode;
};

const Avatar = ({ src, icon, ...args }: AvatarType) => {
  return (
    <Container {...args}>
      {src ? <AvatarImage alt="Avatar" src={src} /> : icon}
    </Container>
  );
};

export default Avatar;
