import { BaseProviderType, PermissionProviderType } from "models";
import { useProfile } from "providers/profile";
import { createContext, useContext, useEffect, useMemo } from "react";
import { SoftphoneIframe } from "./styles";

let webPhoneWindow: Window | null;

type SoftphoneContextType = { webPhoneWindow: Window | null };

const popupSize = { x: 600, y: 400 };

const SoftphoneContext = createContext({} as SoftphoneContextType);

const Provider = ({ children }: BaseProviderType) => {
  const { user, pIdProject, isSagoCore } = useProfile();

  const hasAccess = useMemo(
    () => user?.hasAccessAttend && user?.hasDialerAPI && user?.deUrlWebPhone,
    [user]
  );

  const isNotAdmin = useMemo(
    () => pIdProject && !isSagoCore,
    [pIdProject, isSagoCore]
  );

  const hasPermission = useMemo(
    () => hasAccess && isNotAdmin,
    [hasAccess, isNotAdmin]
  );

  const useIframe = useMemo(() => user?.mustUseIFrameWebPhone ?? false, [user]);

  const iframe = useMemo(() => {
    if (hasPermission && useIframe)
      return (
        <SoftphoneIframe
          title="Softphone"
          src={user!.deUrlWebPhone}
          allow="camera;microphone"
        />
      );
  }, [hasPermission, useIframe, user]);

  useEffect(() => {
    if (hasPermission && !useIframe) {
      if (webPhoneWindow?.closed === false) webPhoneWindow.focus();
      else {
        const { x, y } = popupSize;
        const left = window.screen.width / 2 - x / 2;
        const top = window.screen.height / 2 - y / 2;

        webPhoneWindow = window.open(
          user?.deUrlWebPhone,
          "WebPhone",
          `width=${x},height=${y},left=${left},top=${top}`
        );
      }
    }

    return () => webPhoneWindow?.close();
  }, [hasPermission, useIframe, user]);

  return (
    <SoftphoneContext.Provider value={{ webPhoneWindow }}>
      {children}
      {iframe}
    </SoftphoneContext.Provider>
  );
};

export const SoftphoneProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useSoftphone = () => useContext(SoftphoneContext);
