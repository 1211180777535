import { ReactComponent as CirclePlayIcon } from "assets/icons/circlePlay.svg";
import Button from "components/button";
import { useInterval } from "providers";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PauseAlert } from "./styles";

const attendancePath = "/attendance";

const Interval = () => {
  const interval = useInterval();
  const { loading, selectedItem, formatedDuration } = interval;
  const { handleChangeStatus } = interval;
  const { t } = useTranslation("components");
  const navigate = useNavigate();

  const handleToAnswer = useCallback(async () => {
    await handleChangeStatus().then(() => {
      const path = window.location.pathname;
      if (!path.includes(attendancePath)) navigate(`.${attendancePath}`);
    });
  }, [handleChangeStatus, navigate]);

  if (!selectedItem?.IsPause) return <></>;

  return (
    <PauseAlert>
      <div>
        <p>{selectedItem.DeEventType}</p>
        <span>{t("interval.description")}</span>
        <h3>{formatedDuration}</h3>
      </div>
      <Button
        $small
        loading={loading}
        icon={<CirclePlayIcon />}
        onClick={handleToAnswer}
      >
        {t("interval.toAnswer")}
      </Button>
    </PauseAlert>
  );
};

export default Interval;
