import { AxiosRequestConfig } from "axios";
import {
  GetPermissionsRequest,
  GetPermissionsResponse,
  ManagePermissionRequest,
  ManagePermissionResponse,
} from "models";
import service from "services";

// Manage permission
export const getPermissions = async (
  params: GetPermissionsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetPermissionsResponse>("Role/QueryPermission", {
    ...config,
    params,
  });

export const managePermission = async (
  params: ManagePermissionRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManagePermissionResponse>("Role/ManagerPermission", {
    ...config,
    params,
  });
