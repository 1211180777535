import { AxiosRequestConfig } from "axios";
import {
  GetFAQsRequest,
  GetFAQsResponse,
  ManageFAQRequest,
  ManageFAQResponse,
} from "models";
import service from "services";

// Manage FAQ
export const getFAQs = async (
  params: GetFAQsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFAQsResponse>("OmniFAQ/QueryOmniFAQ", {
    ...config,
    params,
  });

export const manageFAQ = async (
  params: ManageFAQRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageFAQResponse>("OmniFAQ/ManagerOmniFAQ", {
    ...config,
    params,
  });
