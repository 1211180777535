import { useMap } from "hooks";
import {
  BaseProviderType,
  HSMTemplateModel,
  PermissionProviderType,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as HSMService from "services/hsm";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, HSMTemplateModel> | undefined;

type HSMContextType = {
  loading: boolean;
  hsmTemplates?: HSMTemplateModel[];
  activeHSMTemplates?: HSMTemplateModel[];
  getHSMTemplates: () => Promise<void>;
  addHSMTemplates: (
    key: number,
    value: HSMTemplateModel
  ) => Promise<UndefinedMap>;
  editHSMTemplates: (
    key: number,
    value: HSMTemplateModel
  ) => Promise<UndefinedMap>;
  removeHSMTemplates: (key: number) => Promise<UndefinedMap>;
};

const HSMContext = createContext({} as HSMContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, HSMTemplateModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hsmTemplates = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeHSMTemplates = useMemo(
    () => hsmTemplates?.filter((c) => c.IsActive),
    [hsmTemplates]
  );

  const getHSMTemplates = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await HSMService.getHSMTemplates({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(
              new Map(
                data.messageOutboundTemplateList.map((m) => [
                  m.IdMessageOutboundTemplate,
                  m,
                ])
              )
            );
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getHSMTemplates();
  }, [getHSMTemplates]);

  return (
    <HSMContext.Provider
      value={{
        hsmTemplates,
        activeHSMTemplates,
        loading,
        getHSMTemplates,
        addHSMTemplates: add,
        editHSMTemplates: edit,
        removeHSMTemplates: remove,
      }}
    >
      {children}
    </HSMContext.Provider>
  );
};

export const HSMProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useHSM = () => useContext(HSMContext);
