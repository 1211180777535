import { useTranslation } from "react-i18next";
import Button, { ButtonColorType } from "../button";
import Modal from "../modal";
import {
  DialogModalFooter,
  DialogModalHeader,
  DialogModalMain,
} from "./styles";

export type DialogType = {
  show: boolean;
  title: string;
  description: string;
  confirmColor?: ButtonColorType;
  onCancel: () => void;
  onConfirm: () => void;
};

const Dialog = ({
  show,
  title,
  description,
  confirmColor = "danger",
  onCancel,
  onConfirm,
}: DialogType) => {
  const { t } = useTranslation("components");

  return (
    <Modal show={show}>
      <DialogModalHeader title={title} onClose={onCancel} />
      <DialogModalMain>
        <p>{description}</p>
      </DialogModalMain>
      <DialogModalFooter>
        <Button $outline onClick={onCancel}>
          {t("cancel")}
        </Button>
        <Button color={confirmColor} onClick={onConfirm}>
          {t("confirm")}
        </Button>
      </DialogModalFooter>
    </Modal>
  );
};

export default Dialog;
