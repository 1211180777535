import { useMap } from "hooks";
import {
  BaseProviderType,
  CampaignTypeModel,
  PermissionProviderType,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as CampaignService from "services/campaign";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, CampaignTypeModel> | undefined;

type CampaignTypeContextType = {
  loading: boolean;
  campaignTypes?: CampaignTypeModel[];
  activeCampaignTypes?: CampaignTypeModel[];
  getCampaignTypes: () => Promise<void>;
  addCampaignType: (
    key: number,
    value: CampaignTypeModel
  ) => Promise<UndefinedMap>;
  editCampaignType: (
    key: number,
    value: CampaignTypeModel
  ) => Promise<UndefinedMap>;
  removeCampaignType: (key: number) => Promise<UndefinedMap>;
};

const CampaignTypeContext = createContext({} as CampaignTypeContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<
    number,
    CampaignTypeModel
  >();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const campaignTypes = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeCampaignTypes = useMemo(
    () => campaignTypes?.filter((c) => c.IsActive),
    [campaignTypes]
  );

  const getCampaignTypes = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await CampaignService.getCampaignTypes({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(
              new Map(data.campaignTypeList.map((c) => [c.IdCampaignType, c]))
            );
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getCampaignTypes();
  }, [getCampaignTypes]);

  return (
    <CampaignTypeContext.Provider
      value={{
        campaignTypes,
        activeCampaignTypes,
        loading,
        getCampaignTypes,
        addCampaignType: add,
        editCampaignType: edit,
        removeCampaignType: remove,
      }}
    >
      {children}
    </CampaignTypeContext.Provider>
  );
};

export const CampaignTypeProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useCampaignType = () => useContext(CampaignTypeContext);
