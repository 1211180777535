import { AxiosRequestConfig } from "axios";
import {
  GetChannelsRequest,
  GetChannelsResponse,
  ManageChannelRequest,
  ManageChannelResponse,
} from "models";
import service from "services";

// Manage channel
export const getChannels = async (
  params: GetChannelsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetChannelsResponse>("OmniSource/QueryOmniSource", {
    ...config,
    params,
  });

export const manageChannel = async (
  params: ManageChannelRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageChannelResponse>("OmniSource/ManagerOmniSource", {
    ...config,
    params,
  });
