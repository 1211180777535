import { modalRoot } from "consts";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import ModalHeader from "./header";
import { Container, ModalContent, ModalFooter, ModalMain } from "./styles";

type ModalType = {
  show: boolean;
  children: ReactNode;
};

const Modal = ({ show, children, ...args }: ModalType) => {
  if (!modalRoot || !show) return <></>;

  return createPortal(
    <Container {...args}>
      <ModalContent>{children}</ModalContent>
    </Container>,
    modalRoot
  );
};

export { ModalFooter, ModalHeader, ModalMain };
export default Modal;
