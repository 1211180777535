import Button from "components/button";
import Form from "components/form";
import FormGroup from "components/formGroup";
import Modal, { ModalFooter, ModalHeader, ModalMain } from "components/modal";
import Select, { Option } from "components/select";
import { ModalParamsType } from "models";
import useViewModel from "./viewModel";

const SelectProject = (args: ModalParamsType) => {
  const { open, onClose } = args;
  const vm = useViewModel(args);
  const { errors, pIdProject, projectOpts } = vm;
  const { t, onSubmit, setValue } = vm;

  return (
    <Modal show={open}>
      <Form onSubmit={onSubmit}>
        <ModalHeader
          title={t(`selectProject.title`)}
          onClose={pIdProject ? onClose : undefined}
        />
        <ModalMain $isNoOverflow>
          <FormGroup
            required
            label={t("labels.project")}
            error={t(errors?.project?.message ?? "")}
          >
            <Select
              $fullWidth
              placeholder={t("select")}
              defaultValue={pIdProject}
              onChange={(value) => setValue("project", value as number)}
            >
              {projectOpts?.map(({ DeProject, IdProject }) => (
                <Option key={IdProject} value={IdProject}>
                  {DeProject}
                </Option>
              ))}
            </Select>
          </FormGroup>
        </ModalMain>
        <ModalFooter>
          {pIdProject && (
            <Button $outline onClick={onClose}>
              {t("cancel")}
            </Button>
          )}
          <Button type="submit">{t("select")}</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SelectProject;
