import { ReactComponent as ArrowIcon } from "assets/icons/arrowDown.svg";
import { MenuBalloonStyle } from "global.styles";
import styled, { css } from "styled-components";

export type NavDropdownStyleType = {
  $open?: boolean;
  $justIcon?: boolean;
};

export const Container = styled.li`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:has(li.active) .menu-item {
    background-color: var(--white);

    .icon-content {
      color: var(--blue-dark-600);
    }
  }
`;

export const Header = styled.div.attrs({
  className: "menu-item",
})<NavDropdownStyleType>`
  width: 100%;
  height: 4.9rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.4rem;
  gap: 0.4rem;
  border-radius: 0.4rem;

  background: ${({ $open }) => ($open ? "var(--white)" : "transparente")};

  &:hover {
    background: var(--white);
    box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
      0 0.4rem 0.4rem var(--box-shadow);
    cursor: pointer;
  }
`;

export const IconContent = styled.div.attrs({
  className: "icon-content",
})<NavDropdownStyleType>`
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-400);
`;

export const InfoContainer = styled.div`
  flex: 1;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 1;
  word-break: break-all;
`;

export const LabelContent = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: var(--neutral-400);
  text-transform: uppercase;
`;

export const ItemContent = styled.ul<NavDropdownStyleType>`
  display: ${({ $open }) => ($open ? "initial" : "none")};
  margin: 0.4rem 0;

  a {
    color: inherit;
  }

  ${({ $justIcon }) =>
    $justIcon &&
    css`
      ${MenuBalloonStyle}
      top: 50%;
      left: 6rem;
      position: absolute;
      background-color: var(--white);
      border-radius: 0.4rem;
      border: 0.1rem solid var(--neutral-400);
      transform: translateY(-50%);
      z-index: 20;

      li {
        border-radius: 0.4rem;

        div {
          width: auto;
        }

        svg {
          width: 3.2rem;
        }

        &:hover {
          color: var(--white);
          background-color: var(--blue-dark-800);
          box-shadow: initial;
        }
      }

      &::before {
        content: "";
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-right: 0.5rem solid var(--neutral-400);
        position: absolute;
        left: -0.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;

export const Item = styled.li<NavDropdownStyleType>`
  list-style: none;
  padding: 0.75rem 0.4rem;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  &:hover {
    background: var(--white);
    box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
      0 0.4rem 0.4rem var(--box-shadow);
  }

  cursor: pointer;
`;

export const ArrowDonwIcon = styled(ArrowIcon)<NavDropdownStyleType>`
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.9rem;

  transition: 250ms ease-in-out;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;
