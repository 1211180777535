// Models

import { toLocaleFormat } from "consts";
import { format } from "date-fns";
import { SubChannelTypeEnum } from "./attendance";
import { BaseReponseType } from "./fragments";

export type CallReportModel = {
  IdCall: number;
  IdCallDialerAPI: number;
  IdMailing: number;
  IdMailingDialerApi: number;
  IdOmni: number;
  DtStart: string;
  DtFinish: string;
  VlDurationCall: number;
  IdOrder: number;
  DtRegister: string;
  DtLastRegister: string;
  IdProduct: number;
  NmProduct: string;
  IdStatus: number;
  NmStatus: string;
  CodeStatusCrmToPredictive: string;
  NuPhoneWhatsapp: string;
  IdCallType: number;
  NmCallType: string;
  CodeAttendGroup: string;
  NmLoginTelephony: string;
  BindListData?: {
    NmProperty: string;
    VlProperty: string;
  }[];
  DeQuestionnaire: string;
  QuestionListData: any[];
  Obs: string;
};

export type DetailedSalesModel = {
  IdOrder: number;
  IdMailing: number;
  NmCustomer: string;
  NuRegistration: string;
  NmProduct: string;
  DtStartCall: string;
  DtRegister: string;
  DtLastRegister: string;
  NmOrderState: string;
  NmStatus: string;
  DeLogin: string;
  NmUser: string;
  DeLoginUserLastRegister: string;
  NmUserLastRegister: string;
  NmUserParent: string;
  NmUserParentLastRegister: string;
  NuDdd: string;
  NuPhone: string;
  DeObs: string;
};

export type HSMBatchDetailModel = {
  IdHsmBatch: number;
  NmHsmBatch: string;
  DtRegister: string;
  CodeMailingClient: string;
  NuDdd: string;
  NuPhone: string;
  NmMailing: string;
  DeCustom1: string;
  DeCustom2: string;
  DeCustom3: string;
  DeCustom4: string;
  DeCustom5: string;
  IsValidTelephone: boolean;
  DeStatusBroker: string;
};

export type InteractionAndRecordingModel = {
  DeLogin: string;
  NmUser: string;
  NmUserParent: string;
  NmLoginTelephony: string;
  IdMailing: number;
  IdMailingDialerApi: number;
  NmCustomer: string;
  NuRegistration: string;
  CodeNmCampaign: string;
  DtStart: string;
  DtFinish: string;
  VlDurationCall: string;
  NuDdd: string;
  NuPhone: string;
  HasDialerAnswer: boolean;
  NmStatus: string;
  NmChannelType: string;
  NmProduct: string;
  Obs: string;
};

export type InteractionMapModel = {
  DtReference: string;
  IdStatus: string;
  NmStatus: number;
  NmStatusTypeDs: string;
  NmStatusGroup: string;
  QtCalls: number;
  VlPercentCall: string;
  QtSecAvgDurationCall: number;
  QtSecDurationCall: number;
};

export type InteractionModel = {
  DtReference: string;
  NmChannelType: string;
  QtAgentLogged: number;
  QtOomni: number;
  QtOmniAnswered: number;
  VlPercentOmniAnswered: string;
  QtOmniFinalized: number;
  QtOmniPending: number;
  QtOmniExpired: number;
  VlPercentOmniExpired: string;
  QtOmniAbandoned: number;
  QtOmniMessage: number;
  QtOmniMessageReceived: number;
  QtOmniMessageSent: number;
  VlAvgWaitTime: number;
  VlAvgAttendTime: number;
  VlAvgFirstAnswerTime: number;
};

export type LicenseAnalyticModel = {
  IdProject: number;
  DeProject: string;
  IdUser: number;
  NmUser: string;
  DeLogin: string;
  NuRegistration: string;
  NmLoginTelephony: string;
  DtRegister: string;
  DtInactive: string;
  IsActive: boolean;
  IsActivePeriod: boolean;
  HasCall: boolean;
};

export type LicenseConsolidatedModel = {
  IdProject: number;
  DeProject: string;
  QtUser: number;
  QtUserCall: number;
};

export type MessageHistoryModel = {
  IdOmni: string;
  IdMailing: number;
  NmMailing: string;
  NuPhoneWhatsapp: string;
  DtStart: string;
  IdSubChannelType: SubChannelTypeEnum;
};

export class MessageItemExportClass {
  IdOmni: number;
  NmMailingFrom: string;
  NmMailingTo: string;
  DtRegister: string;
  DeOmniMessage: string;

  constructor(m: MessageItemModel) {
    this.IdOmni = m.IdOmni;
    this.NmMailingFrom = m.NmMailingFrom;
    this.NmMailingTo = m.NmMailingTo;
    this.DtRegister = format(new Date(m.DtRegister), toLocaleFormat);
    this.DeOmniMessage = m.DeOmniMessage;
  }
}

export type MessageItemModel = {
  IdOmni: number;
  NmMailingFrom: string;
  NmMailingTo: string;
  DtRegister: string;
  DeOmniMessage: string;
  IsFromMailing: boolean;
  IsFromUserOrCRM: boolean;
};

export type MessageUserDataModel = {
  NmData: string;
  VlData: string;
};

export type OperationPerformanceModel = {
  NmGroupBy: string;
  QtCall: number;
  VlPercCallApproach: string;
  VlPercOrderApproach: string;
  VlPercOrderCall: string;
  QtOrder: number;
  QtCallApproach: number;
  QtUser: number;
  QtMailingWorked: number;
  QtDevice: number;
  QtDaysWorked: number;
  VlPercOrderGroup: string;
  VlAvgOrderUser: string;
  VlAvgMailingWorkedDevice: string;
  VlAvgOrderDaysWorked: string;
};

export type OrderReportModel = {
  IdProject: number;
  IdOrder: number;
  IdMailingDialerApi: number;
  IdCallDialerApi: number;
  DtRegister: string;
  DtLastRegister: string;
  CodeUserRegister: string;
  DeLoginRegister: string;
  NmLoginTelephonyRegister: string;
  DeLoginRegisterParent: string;
  DeLoginLastRegister: string;
  NmLoginTelephonyLastRegister: string;
  DeLoginLastRegisterParent: string;
  CodeUserRegisterParent: string;
  CodeUserLastRegister: string;
  CodeUserLastRegisterParent: string;
  NmStatus: string;
  CodeStatusCrmToPredictive: string;
  NmOrderState: string;
  IsOrderOk: boolean;
  IsFinishedState: boolean;
  IdProduct: number;
  NmProduct: string;
  VlProduct: string;
};

export type OverviewModel = {
  DtReference: string;
  IdProject: number;
  DeProject: string;
  QtOmniVoice: number;
  QtOmniChat: number;
  QtOmniMessage: number;
  QtAgentLogged: number;
  DtFirstAttend: string;
  DtLastAttend: string;
};

export type ticketAnalyticModel = {
  NmCustomer: string;
  NuDDD: string;
  NuPhone: string;
  DtRegister: string;
  CodeProtocol: string;
  DeObs: string;
  NmStatusProduct1: string;
  NmStatusProduct2: string;
  NmStatusProduct3: string;
  DeLogin: string;
  NmUser: string;
  NmStatus: string;
};

export type ticketConsolidatedModel = {
  NmUser: string;
  NmUserParent: string;
  QtTicket: number;
  QtTicketNotFinalized: number;
  QtTicketFinalized: number;
};

// Requests

type BaseRequestType = {
  pIdProject: number;
  pDtStart: string;
  pDtFinish: string;
};

export type GetCallReportRequest = BaseRequestType;

export type GetDetailedSalesRequest = BaseRequestType & {
  pIdOrderState?: number[];
};

export type GetHSMBatchRequest = Pick<BaseRequestType, "pIdProject"> & {
  pIdHsmBatch: number;
};

export type GetInteractionAndRecordingRequest = BaseRequestType;

export type GetInteractionsRequest = BaseRequestType;

export type GetInteractionMapsRequest = BaseRequestType;

export type GetLicenseAnalyticRequest = BaseRequestType;

export type GetLicenseConsolidatedRequest = BaseRequestType;

export type GetMessageHistoryRequest = BaseRequestType & {
  pNmMailing?: string;
  pNuDDD?: string;
  pNuPhone?: string;
  pIdSubChannelType?: number;
  pIdUser?: number[];
  pIdAttendGroup?: number[];
};

export type GetMessageListRequest = {
  pIdOmni: string;
};

export type GetMessageUserDataRequest = {
  pIdMailing: number;
};

export type GetOperationsPerformanceRequest = BaseRequestType;

export type GetOrderReportRequest = BaseRequestType;

export type GetOverviewRequest = Omit<BaseRequestType, "pIdProject">;

export type GetTicketAnalyticRequest = BaseRequestType;

export type GetTicketConsolidatedRequest = BaseRequestType;

// Responses

export type GetCallReportResponse = BaseReponseType & {
  callDataList: CallReportModel[];
};

export type GetDetailedSalesResponse = BaseReponseType & {
  pendingAndCanceledOrderList: DetailedSalesModel[];
};

export type GetHSMBatchResponse = BaseReponseType & {
  hsmBatchItemDataList: HSMBatchDetailModel[];
};

export type GetInteractionAndRecordingResponse = BaseReponseType & {
  omniAnalyticInteractionsList: InteractionAndRecordingModel[];
};

export type GetInteractionsResponse = BaseReponseType & {
  omniInteractionsList: InteractionModel[];
  qtOmniFinalizedTotal: number;
  qtOmniPendingTotal: number;
  qtOmniExpiredTotal: number;
  qtOmniAbandonedTotal: number;
  qtOmniMessageTotal: number;
  qtOmniMessageReceivedTotal: number;
  qtOmniMessageSentTotal: number;
};

export type GetInteractionMapsResponse = BaseReponseType & {
  omniCallMappingList: InteractionMapModel[];
  qtCallsTotal: number;
  qtSecAvgDurationCallTotal: number;
  qtSecDurationCallTotal: number;
};

export type GetLicenseAnalyticResponse = BaseReponseType & {
  licenseCountCrmCloudDataList: LicenseAnalyticModel[];
};

export type GetLicenseConsolidatedResponse = BaseReponseType & {
  licenseCountCrmCloudConsList: LicenseConsolidatedModel[];
  qtUserTotal: number;
  qtUserCallTotal: number;
};

export type GetMessageHistoryResponse = BaseReponseType & {
  omniHistoryList: MessageHistoryModel[];
};

export type GetMessageListResponse = BaseReponseType & {
  omniHistoryMessageList: MessageItemModel[];
};

export type GetMessageUserDataResponse = BaseReponseType & {
  omniHistoryCustomerDataList: MessageUserDataModel[];
};

export type GetOperationsPerformanceResponse = BaseReponseType & {
  performanceList: OperationPerformanceModel[];
  qtCallTotal: number;
  qtOrderTotal: number;
  qtCallApproachTotal: number;
  qtUserTotal: number;
  qtMailingWorkedTotal: number;
  qtDeviceTotal: number;
  qtDaysWorkedTotal: number;
};

export type GetOrderReportResponse = BaseReponseType & {
  orderDataList: OrderReportModel[];
};

export type GetOverviewResponse = BaseReponseType & {
  omniDashboardProjectList: OverviewModel[];
  qtOmniVoiceTotal: number;
  qtOmniChatTotal: number;
  qtOmniMessageTotal: number;
  qtAgentLoggedTotal: number;
};

export type GetTicketAnalyticResponse = BaseReponseType & {
  ticketAnalyticList: ticketAnalyticModel[];
};

export type GetTicketConsolidatedResponse = BaseReponseType & {
  ticketConsolidatedList: ticketConsolidatedModel[];
  qtTicketTotal: number;
  qtTicketNotFinalizedTotal: number;
  qtTicketFinalizedTotal: number;
};
