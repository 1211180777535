import axios, { AxiosRequestConfig } from "axios";
import { ss, tokenKey, unauthorizedCode, unauthorizedToken } from "consts";
import { BaseReponseType } from "models";
import { clearLocalStorage } from "utils";

const CancelToken = axios.CancelToken;
const pendingRequest = new Map();

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 120000,
});

service.interceptors.request.use(
  (request) => {
    const currentToken = ss.getItem(tokenKey);

    if (currentToken)
      request.params = { pDeToken: currentToken, ...request.params };

    const requestKey = getReqKey(request);

    if (pendingRequest.has(requestKey)) removeReqKey(requestKey);
    else
      request.cancelToken = new CancelToken((cancel) =>
        pendingRequest.set(requestKey, cancel)
      );

    return request;
  },
  (error) => Promise.reject(error)
);

const logOutUser = () => {
  const currentToken = ss.getItem(tokenKey);
  if (currentToken) {
    clearLocalStorage();
    window.location.replace("/authentication?loggedOut");
  }
};

service.interceptors.response.use(
  (response) => {
    removeReqKey(getReqKey(response.config));

    if (response.data?.idReturnAPI === unauthorizedCode) logOutUser();

    return response;
  },
  (error) => {
    removeReqKey(getReqKey(error.config));

    if (axios.isCancel(error)) return new Promise(() => {});
    else if (error?.response?.status === unauthorizedToken) logOutUser();

    return Promise.reject(error);
  }
);

const getReqKey = (config: AxiosRequestConfig) =>
  `${config?.method}-${config?.url}}`;

const removeReqKey = (key: string) => {
  const cancelToken = pendingRequest.get(key);
  cancelToken?.(key);
  pendingRequest.delete(key);
};

export const fetchSync = <T extends BaseReponseType>(
  method: string,
  path: string,
  params?: object,
  body?: XMLHttpRequestBodyInit
): T => {
  const pDeToken = ss.getItem(tokenKey);

  let baseURL = process.env.REACT_APP_BASE_URL;

  const URL = `${baseURL}/${path}?${Object.entries({ pDeToken, ...params })
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;

  const res = new XMLHttpRequest();
  res.open(method, URL, false);
  res.send(body);

  if (res.status === unauthorizedToken) logOutUser();
  else if (res.status !== 200) throw new Error(res.statusText, { cause: res });

  const response: T = JSON.parse(res.response);

  if (response.idReturnAPI === unauthorizedCode) logOutUser();

  return response;
};

export default service;
