import { AxiosRequestConfig } from "axios";
import {
  GetIntervalOptsRequest,
  GetIntervalOptsResponse,
  GetIntervalsRequest,
  GetIntervalsResponse,
  ManageIntervalRequest,
  ManageIntervalResponse,
  SelectIntervalRequest,
  SelectIntervalResponse,
} from "models";
import service from "services";

// Interval
export const getIntervals = async (
  params: GetIntervalsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetIntervalsResponse>("Event/QueryEventTypePause", {
    ...config,
    params,
  });

export const getIntervalOpts = async (
  params: GetIntervalOptsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetIntervalOptsResponse>("Event/GetEventTypeList", {
    ...config,
    params,
  });

export const manageInterval = async (
  params: ManageIntervalRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageIntervalResponse>("Event/ManagerEventTypePause", {
    ...config,
    params,
  });

export const selectInterval = async (
  params: SelectIntervalRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SelectIntervalResponse>("Event/SetEventType", {
    ...config,
    params,
  });
