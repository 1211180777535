// Models

import { BaseReponseType } from "models";

export class TicketExportClass {
  IdTicket: number;
  IdTicketState: number;
  NmTicketState: string;
  IdTicketType: number;
  NmTicketType: string;
  IdLastTicketEvent: number;
  NuGuidDts: number;
  IdMailing: number;
  NmMailing: string;
  DtRegister: string;
  IdUserRegister: number;
  NmUserRegister: string;
  DtLastRegister: string;
  IdUserLastRegister: number;
  NmUserLastRegister: string;
  IdTicketCell: number;
  NmTicketCell: string;
  DeObs: string;
  CodeProtocolIssue: string;
  CodeProtocol: string;
  IdTicketReason: number;
  NmTicketReason: string;
  IdTicketSubReason: number;
  NmTicketSubReason: string;
  DeDescription: string;
  DeEmailRequest1: string;
  NuDddRequest1: string;
  NuPhoneRequest1: string;
  NmRequest: string;

  constructor(t: TicketModel) {
    this.IdTicket = t.IdTicket;
    this.IdTicketState = t.IdTicketState;
    this.NmTicketState = t.NmTicketState;
    this.IdTicketType = t.IdTicketType;
    this.NmTicketType = t.NmTicketType;
    this.IdLastTicketEvent = t.IdLastTicketEvent;
    this.NuGuidDts = t.NuGuidDts;
    this.IdMailing = t.IdMailing;
    this.NmMailing = t.NmMailing;
    this.DtRegister = t.DtRegister;
    this.IdUserRegister = t.IdUserRegister;
    this.NmUserRegister = t.NmUserRegister;
    this.DtLastRegister = t.DtLastRegister;
    this.IdUserLastRegister = t.IdUserLastRegister;
    this.NmUserLastRegister = t.NmUserLastRegister;
    this.IdTicketCell = t.IdTicketCell;
    this.NmTicketCell = t.NmTicketCell;
    this.DeObs = t.DeObs;
    this.CodeProtocolIssue = t.CodeProtocolIssue;
    this.CodeProtocol = t.CodeProtocol;
    this.IdTicketReason = t.IdTicketReason;
    this.NmTicketReason = t.NmTicketReason;
    this.IdTicketSubReason = t.IdTicketSubReason;
    this.NmTicketSubReason = t.NmTicketSubReason;
    this.DeDescription = t.DeDescription;
    this.DeEmailRequest1 = t.DeEmailRequest1;
    this.NuDddRequest1 = t.NuDddRequest1;
    this.NuPhoneRequest1 = t.NuPhoneRequest1;
    this.NmRequest = t.NmRequest;
  }
}

export type TicketModel = {
  IdTicket: number;
  IdTicketState: number;
  NmTicketState: string;
  IdTicketType: number;
  NmTicketType: string;
  IdLastTicketEvent: number;
  NuGuidDts: number;
  IdMailing: number;
  NmMailing: string;
  DtRegister: string;
  IdUserRegister: number;
  NmUserRegister: string;
  DtLastRegister: string;
  IdUserLastRegister: number;
  NmUserLastRegister: string;
  IdTicketCell: number;
  NmTicketCell: string;
  DeObs: string;
  CodeProtocolIssue: string;
  CodeProtocol: string;
  IdTicketReason: number;
  NmTicketReason: string;
  IdTicketSubReason: number;
  NmTicketSubReason: string;
  IsEditing: false;
  DeDescription: string;
  DeEmailRequest1: string;
  NuDddRequest1: string;
  NuPhoneRequest1: string;
  NmRequest: string;
  IdCall: number;
  IdLastCall: number;
  IdOmni: number;
  IdLastOmni: number;
  BindListData?: {
    NmControl: string;
    VlProperty: string;
    NmColumn: string;
  }[];
};

// Requests

export type GetTicketsRequest = {
  pIdProject: number;
  pDtStart: string;
  pDtFinish: string;
  pIdTicketState?: number;
  pIdTicketCell?: number;
  pIdTicketReason?: number;
  pIdTicketSubReason?: number;
  pDeDescription: string;
  pCodeProtocolIssue: string;
};

// Responses

export type GetTicketsResponse = BaseReponseType & {
  ticketList: TicketModel[];
};
