// Models

import { BaseReponseType } from "models";

export class ProductExportClass {
  IdProduct: number;
  NmProduct: string;
  VlProduct?: number;
  IsActive: boolean;

  constructor(p: ProductModel) {
    this.IdProduct = p.IdProduct;
    this.NmProduct = p.NmProduct;
    this.VlProduct = p.VlProduct;
    this.IsActive = p.IsActive;
  }
}

export type ProductModel = {
  IdProduct: number;
  NmProduct: string;
  VlProduct?: number;
  IsActive: boolean;
  IdProject: number;
};

// Requests

export type GetProductsRequest = {
  pIdProject: number;
  pNmProduct?: string;
};

export type ManageProductRequest = {
  pIdProject: number;
  pIdProduct: number;
  pNmProduct: string;
  pVlProduct: number;
  pIsActive: boolean;
};

// Responses

export type GetProductsResponse = BaseReponseType & {
  productList: ProductModel[];
};

export type ManageProductResponse = BaseReponseType;
