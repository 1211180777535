import styled from "styled-components";

type ModalMainStyleType = {
  $isNoOverflow?: boolean;
};

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10vh;
  z-index: 15;
`;

export const ModalContent = styled.div`
  width: 40rem;
  background-color: var(--white);
  border-radius: 0.4rem;
  animation: modal-fade-in 0.5s;

  &:has(footer) main {
    max-height: calc(80vh - 14.6rem);
  }

  @keyframes modal-fade-in {
    from {
      transform: translateY(-1rem);
    }

    to {
      transform: translateY(0);
    }
  }
`;

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  border-bottom: 0.1rem solid var(--gray);
  gap: 1.6rem;

  > h3 {
    font-weight: normal;
  }
`;

export const ModalMain = styled.main<ModalMainStyleType>`
  position: relative;
  width: 100%;
  max-height: calc(80vh - 7.3rem);
  transition: height ease-in-out 0.2s;
  padding: 1.6rem;
  overflow-y: ${({ $isNoOverflow }) => ($isNoOverflow ? "initial" : "auto")};
`;

export const ModalFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.8rem;
  padding: 1.6rem;
  border-radius: 0 0 0.4rem 0.4rem;
  border-top: 0.1rem solid var(--gray);
`;
