import { MyUserModel } from "models";
import {
  CampaignProvider,
  CampaignTypeProvider,
  ChannelProvider,
  FAQProvider,
  FormProvider,
  HSMProvider,
  HeaderProvider,
  IntervalProvider,
  ListProvider,
  PermissionProvider,
  ProductProvider,
  ProjectProvider,
  QueueProvider,
  SoftphoneProvider,
  StatusProvider,
  UserProvider,
  VideoCallProvider,
  useProfile,
} from "providers";
import { ReactNode, useMemo } from "react";
import Content from "./content";
import Header from "./header";
import Interval from "./interval";
import SideBar from "./sideBar";
import { LayoutContainer, LayoutContent } from "./styles";

type BaseComponentType = {
  children: ReactNode;
  user?: MyUserModel;
};

const BaseProviders = ({ children }: BaseComponentType) => {
  return (
    <ListProvider>
      <SoftphoneProvider>
        <VideoCallProvider>
          <IntervalProvider>
            <HeaderProvider>
              <UserProvider>
                <QueueProvider>{children}</QueueProvider>
              </UserProvider>
            </HeaderProvider>
          </IntervalProvider>
        </VideoCallProvider>
      </SoftphoneProvider>
    </ListProvider>
  );
};

const BaseLayout = () => {
  return (
    <LayoutContainer>
      <SideBar />
      <LayoutContent>
        <Interval />
        <LayoutContent>
          <Header />
          <Content />
        </LayoutContent>
      </LayoutContent>
    </LayoutContainer>
  );
};

const Layout = () => {
  const { user } = useProfile();

  const hasPermission = useMemo(() => user?.hasAccessAdmin ?? false, [user]);

  const hasRegister = useMemo(() => user?.hasAccessRegister ?? false, [user]);

  const hasAttendanceOrRegister = useMemo(
    () =>
      (user?.hasAccessAttend ?? false) || (user?.hasAccessRegister ?? false),
    [user]
  );

  const hasRegisterOrReport = useMemo(
    () =>
      (user?.hasAccessRegister ?? false) || (user?.hasAccessReport ?? false),
    [user]
  );

  return (
    <BaseProviders>
      <PermissionProvider hasPermission={hasPermission}>
        <StatusProvider hasPermission={hasPermission}>
          <FormProvider hasPermission={hasPermission}>
            <ProjectProvider hasPermission={hasPermission}>
              <CampaignTypeProvider hasPermission={hasPermission}>
                <CampaignProvider hasPermission={hasRegisterOrReport}>
                  <ChannelProvider hasPermission={hasAttendanceOrRegister}>
                    <ProductProvider hasPermission={hasRegister}>
                      <HSMProvider hasPermission={hasRegister}>
                        <FAQProvider hasPermission={hasAttendanceOrRegister}>
                          <BaseLayout />
                        </FAQProvider>
                      </HSMProvider>
                    </ProductProvider>
                  </ChannelProvider>
                </CampaignProvider>
              </CampaignTypeProvider>
            </ProjectProvider>
          </FormProvider>
        </StatusProvider>
      </PermissionProvider>
    </BaseProviders>
  );
};

export default Layout;
