type MaskOptionType = {
  reverse?: boolean;
};

export type MaskType =
  | "birthDate"
  | "cnpj"
  | "cpf"
  | "ddd"
  | "dddCustom"
  | "dddPhone"
  | "ddiPhone"
  | "decimal"
  | "number"
  | "phone"
  | "time"
  | "zipCode";

const reverseString = (text: string) => text.split("").reverse().join("");

const patternToMask = (
  value: string,
  pattern: string,
  option?: MaskOptionType
) => {
  const req = pattern.replace(/[^#]/g, "").length;

  let position = 0;
  let newValue = value;
  let newPattern = pattern;

  if (option?.reverse) {
    newValue = reverseString(newValue.slice(0, req));
    newPattern = reverseString(newPattern);
  }

  const replaceResult = newPattern
    .replace(/(#{1,})|(\*{1,})/g, (element) => {
      if (element.includes("*") && newValue.length <= req) return "";
      const length = element.length;
      const result = newValue.slice(position, position + length);
      position += length;
      return result;
    })
    .replace(/(\W{1,})$/, "");

  return option?.reverse ? reverseString(replaceResult) : replaceResult;
};

export const applyCustomMask = (value: string, mask: string) => {
  if (!value || !mask) return value;

  const current = value.replace(/\D/g, "");
  const replaceMask = mask.replace(/\d/g, "#");

  return patternToMask(current, replaceMask);
};

export const applyMask = (value: string, mask: MaskType) => {
  if (!value) return value;

  const current = value.replace(/\D/g, "");

  switch (mask) {
    case "birthDate":
      return patternToMask(current, "##/##/####");
    case "cnpj":
      return patternToMask(current, "##.###.###/####-##");
    case "cpf":
      return patternToMask(current, "###.###.###-##");
    case "ddd":
      return patternToMask(current, "##");
    case "dddCustom":
      return patternToMask(current, "####");
    case "dddPhone":
      return patternToMask(current, "(##) *####-####");
    case "ddiPhone":
      return patternToMask(current, "## (##) *####-####");
    case "decimal":
      return patternToMask(current, "###.###.###.###,##", { reverse: true });
    case "phone":
      return patternToMask(current, "*####-####");
    case "time":
      return patternToMask(current, "##:##:##");
    case "zipCode":
      return patternToMask(current, "#####-###");
    case "number":
      return current;
    default:
      return value;
  }
};

export const removeMask = (value: string, type?: MaskType) => {
  if (!value) return value;

  switch (type) {
    case "decimal":
      return value.replace(".", "").replace(",", ".");
    default:
      return value.replace(/\D/g, "");
  }
};
