import {
  allFormsKey,
  allHistoriesKey,
  allIntegrationsKey,
  allMsgsKey,
  allOrdersKey,
  allQuestionsKey,
  allTicketsKey,
  aytyErrorLabel,
  dayFormat,
  externalReportPath,
  formatDistanceLocale,
  fullTimeFormat,
  projectKey,
  sortByKey,
  ss,
  statusKey,
  timeFormat,
  toLocaleFormat,
  todayPeriod,
  tokenKey,
  unauthorizedCode,
  whatsappMimeType,
  yesterdayPeriod,
} from "consts";
import { format, sub } from "date-fns";
import {
  ActionRecordAudioType,
  BaseReponseType,
  FormFieldModel,
  RecordAudioType,
} from "models";
import { FilterReportType } from "pages/report/viewModel";
import { RefObject } from "react";
import { validateDate } from "utils";

const Logo = require("assets/imgs/logoFavicon.svg");

export const addTimeSuffix = (date: string, isEnd?: boolean) => {
  const suffix = isEnd ? "23:59:59" : "00:00:00";
  return `${format(
    new Date(date.length > 10 ? date : `${date} 12:00`),
    dayFormat
  )} ${suffix}`;
};

export const arrayEquals = <T extends any>(
  first: Array<T>,
  second: Array<T>
) => {
  return (
    first.length === second.length &&
    first.every((val, index) => val === second[index])
  );
};

export const audioNotification = (path: string) => {
  const audio = new Audio(path);
  audio.play();
};

export const aytyFormatError = <T extends Omit<BaseReponseType, "dtServer">>(
  data: T
) => {
  if (data.idReturnAPI === unauthorizedCode) return "errors.loggedOutUser";
  else return data.deReturnAPI.replace(aytyErrorLabel, "").trim();
};

export const clearLocalStorage = () => {
  ss.removeItem(tokenKey);
  ss.removeItem(projectKey);
  ss.removeItem(statusKey);
  ss.removeItem(sortByKey);
  ss.removeItem(allFormsKey);
  ss.removeItem(allHistoriesKey);
  ss.removeItem(allIntegrationsKey);
  ss.removeItem(allMsgsKey);
  ss.removeItem(allTicketsKey);
  ss.removeItem(allOrdersKey);
  ss.removeItem(allQuestionsKey);
};

export const clipboard = (clip?: string) => {
  return navigator.clipboard.writeText(clip || "");
};

export const debounce = (callback: Function, timeout = 300) => {
  let timer: NodeJS.Timeout;

  const cancel = () => clearTimeout(timer);

  const debounced = (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(this, args), timeout);
  };

  debounced.cancel = cancel;

  return debounced;
};

export const detailFields = (form?: FormFieldModel[]) => {
  const filters = new Map<string, string | number>();

  return form?.map((field) => {
    if (field.HasDetail)
      filters.set(field.NmsControlsBindsDetails, field.VlProperty);

    if (filters.has(field.NmControl))
      return {
        ...field,
        comboList: field.comboList.filter(
          ({ valueParent }) =>
            !valueParent || valueParent === filters.get(field.NmControl)
        ),
      };

    return field;
  });
};

export const equalObjects = <T extends object>(
  base: T,
  compare: T,
  ignore?: (keyof T)[]
) =>
  Object.entries(base).every(([key, value]) => {
    if (ignore?.includes(key as keyof T)) return true;

    const otherValue = compare[key as keyof T];

    if (Array.isArray(value) && Array.isArray(otherValue))
      return otherValue?.toString() === value.toString();

    return otherValue === value;
  });

export const exitElement =
  (ref: RefObject<HTMLElement>, callback?: () => void) => (e: Event) => {
    if (
      e.target !== ref?.current &&
      !ref?.current?.contains(e.target as Node)
    ) {
      callback?.();
    }
  };

export const extractFileContent = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.onload = async ({ target }) =>
        resolve(target?.result?.toString() || "");
    } catch (error) {
      return reject(new Error("Erro ao extrair texto do arquivo"));
    }
    reader.readAsText(file);
  });
};

export const formatAgentName = (name = "", ignoreLastName = false) => {
  const names = name.trim().split(" ");
  if (names.length > 1 && !ignoreLastName)
    return `${names[0]} ${names[names.length - 1]}`;
  return names[0];
};

export const formatDistance = (token: any, count: any, options?: any) => {
  const result = formatDistanceLocale[token].replace("{{count}}", count);

  if (!options.addSuffix) return result;

  if (options.comparison > 0) return "in " + result;
  else return result + " ago";
};

export const formatText = (text: string) => {
  return text.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, "\n");
};

export const generateUID = () => {
  const numToBase16 = (num: number) => Math.floor(num).toString(16);
  return (
    numToBase16(Date.now() / 1000) +
    " ".repeat(16).replace(/./g, () => numToBase16(Math.random() * 16))
  );
};

export const getEndDateByPeriod = (current: Date, period: number) => {
  let newDate = current;
  if (period === yesterdayPeriod) newDate = sub(current, { days: 1 });
  return `${format(newDate, dayFormat)}T23:59`;
};

export const getDateByText = (text?: string, minify?: boolean) => {
  if (!text || isNaN(Date.parse(text))) return "--:--";

  const date = new Date(text);
  if (minify && format(date, dayFormat) === format(new Date(), dayFormat))
    return format(date, timeFormat);
  else return format(date, toLocaleFormat);
};

export const getRandomNumber = (length = 1) => {
  return Array(length)
    .fill(null)
    .map(() => Math.floor(Math.random() * 10))
    .join("");
};

export const getStartDateByPeriod = (current: Date, period: number) => {
  let newDate = current;
  if (period === yesterdayPeriod) newDate = sub(current, { days: 1 });
  else if (period !== todayPeriod) newDate = sub(current, { days: period });
  return `${format(newDate, dayFormat)}T00:00`;
};

export const insertInCursorPointer = (
  ref: RefObject<HTMLTextAreaElement | HTMLInputElement>,
  text: string
) => {
  if (ref.current) {
    const event = new Event("change", { bubbles: true, cancelable: true });
    const { value, selectionStart, selectionEnd } = ref.current;
    const previousText = value.slice(0, selectionStart || value.length);
    const nextText = value.slice(selectionEnd || value.length);
    ref.current.value = `${previousText}${text}${nextText}`;
    ref.current.dispatchEvent(event);
  }
};

export const notification = (title: string, text: string) => {
  if (!window.Notification) {
    console.log("Browser does not support notifications.");
    return;
  }

  const notify = () =>
    new Notification(title, { body: text, icon: Logo.default });

  if (Notification.permission === "granted") notify();
  else if (Notification.permission !== "denied")
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") notify();
        else console.log("User blocked notifications.");
      })
      .catch(console.error);
};

export const objectToFormData = (obj: Object) => {
  const formData = new FormData();
  Object.entries(obj).map(([key, value]) => formData.append(key, value));
  return formData;
};

export const openExternalReport = (params: FilterReportType) => {
  window.open(
    `${externalReportPath}?${objectToURLParams(params)}`,
    "ExternalReport",
    "noopener=true"
  );
};

export const objectToURLParams = (obj: Object) => {
  return Object.entries(obj)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((item) => `${key}=${encodeURIComponent(item)}`).join("&")
        : `${key}=${encodeURIComponent(value)}`
    )
    .filter((item) => !!item)
    .join("&");
};

export const recordAudio = () =>
  new Promise<RecordAudioType>((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        let fragments: Blob[] = [];

        recorder.addEventListener("dataavailable", ({ data }) =>
          fragments.push(data)
        );

        const start = () => recorder.start();

        const stop = () =>
          new Promise<ActionRecordAudioType>((resolve) => {
            const stopResolve = () => {
              const blob = new Blob(fragments, { type: whatsappMimeType });
              const path = URL.createObjectURL(blob);
              const audio = new Audio(path);

              resolve({ blob, path, audio });
            };

            if (recorder.state === "inactive") stopResolve();

            recorder.addEventListener("stop", stopResolve);
            recorder.stop();
          });

        const finish = () =>
          stop().finally(() => {
            stream.getTracks().forEach((track) => {
              track.stop();
              track.enabled = false;
            });
          });

        resolve({ start, stop, finish });
      })
      .catch(reject);
  });

export const secondsToTime = (seconds?: number, path = fullTimeFormat) => {
  if (!seconds) return "";

  const date = new Date("01-01-0001");
  date.setSeconds(seconds);

  return format(date, path)
    .replaceAll(/00{1}./gm, "")
    .trim();
};

export const sortBy = <T extends any>(
  list: T[] = [],
  param?: keyof T,
  desc?: boolean
) => {
  if (!param)
    return list.sort((a, b) =>
      Intl.Collator().compare(a as string, b as string)
    );

  return list.sort((a, b) => {
    let currentA: any = a[param] ?? a;
    let currentB: any = b[param] ?? b;

    if (typeof currentA === "number" && typeof currentB === "number") {
      let result = 0;

      if (currentA > currentB) result = 1;
      else if (currentA < currentB) result = -1;

      return desc ? result * -1 : result;
    }

    currentA = currentA.toLowerCase();
    currentB = currentB.toLowerCase();

    return desc
      ? Intl.Collator().compare(currentB, currentA)
      : Intl.Collator().compare(currentA, currentB);
  });
};

export const splitDate = (text?: string) => {
  if (!text) return "--:--";
  return text.split(" ");
};

export const timeResolver = (
  value: string,
  options?: Intl.DateTimeFormatOptions
) => {
  if (validateDate(value))
    return new Date(value).toLocaleTimeString("pt-BR", options);
  return value;
};

export const timeToSeconds = (time = "") => {
  const [hour = "", min = "", sec = ""] = time.split(":");
  return Number(hour) * 60 * 60 + Number(min) * 60 + Number(sec);
};

export const toBase64 = (text: string) =>
  window.btoa(unescape(encodeURIComponent(text)));

export const updateFields = (
  index: number,
  value: any,
  form?: FormFieldModel[]
) => {
  if (form?.[index]) form[index] = { ...form[index], VlProperty: value };

  if (form?.[index].HasDetail) {
    let nmControl = form[index].NmsControlsBindsDetails;

    for (let count = index + 1; count < form.length; count++) {
      if (form[count].NmControl === nmControl) {
        form[count] = { ...form[count], VlProperty: 0 };

        if (form[count].HasDetail)
          nmControl = form[count].NmsControlsBindsDetails;
      }
    }
  }

  return form;
};

export class WorkerInterval {
  worker: Worker;

  constructor(callback: Function, interval: number) {
    const blob = new Blob([`setInterval(() => postMessage(0), ${interval});`]);
    const workerScript = URL.createObjectURL(blob);
    this.worker = new Worker(workerScript);
    this.worker.onmessage = () => callback();
  }

  stop() {
    this.worker.terminate();
  }
}
