import { ReactNode } from "react";
import { Container, ErrorForm, LabelForm } from "./styles";

type FormGroupType = {
  children: ReactNode;
  label?: string;
  error?: string;
  required?: boolean;
};

const FormGroup = ({
  children,
  label,
  error,
  required,
  ...args
}: FormGroupType) => {
  return (
    <Container {...args}>
      {label && (
        <LabelForm>
          {label}
          {required && <span> *</span>}
        </LabelForm>
      )}
      {children}
      {error && <ErrorForm>{error}</ErrorForm>}
    </Container>
  );
};

export default FormGroup;
