import { useMap } from "hooks";
import { BaseProviderType, PermissionProviderType, QueueModel } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as QueueService from "services/queue";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, QueueModel> | undefined;

type QueueContextType = {
  loading: boolean;
  queues?: QueueModel[];
  activeQueues?: QueueModel[];
  getQueues: () => Promise<void>;
  addQueue: (key: number, value: QueueModel) => Promise<UndefinedMap>;
  editQueue: (key: number, value: QueueModel) => Promise<UndefinedMap>;
  removeQueue: (key: number) => Promise<UndefinedMap>;
};

const QueueContext = createContext({} as QueueContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, QueueModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const queues = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeQueues = useMemo(
    () => queues?.filter((q) => q.IsActive),
    [queues]
  );

  const getQueues = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await QueueService.getQueues({ pIdProject })
        .then(({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.attendGroupList.map((q) => [q.IdAttendGroup, q])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getQueues();
  }, [getQueues]);

  return (
    <QueueContext.Provider
      value={{
        loading,
        queues,
        activeQueues,
        getQueues,
        addQueue: add,
        editQueue: edit,
        removeQueue: remove,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
};

export const QueueProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useQueue = () => useContext(QueueContext);
