import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactNode, useEffect } from "react";
import IconButton from "../iconButton";
import { HeaderContainer } from "./styles";

type ModalHeaderType = {
  children?: ReactNode;
  title?: string;
  onClose?: () => void;
};

const ModalHeader = ({
  children,
  title,
  onClose,
  ...args
}: ModalHeaderType) => {
  useEffect(() => {
    const hide = (e: KeyboardEvent) => e.key === "Escape" && onClose?.();
    document.addEventListener("keydown", hide, false);
    return () => document.removeEventListener("keydown", hide, false);
  }, [onClose]);

  return (
    <HeaderContainer {...args}>
      {children ?? <h3>{title}</h3>}
      {onClose && (
        <IconButton
          $small
          $outline
          color="text"
          icon={<CloseIcon />}
          onClick={onClose}
        />
      )}
    </HeaderContainer>
  );
};

export default ModalHeader;
