// Models

import { BaseReponseType } from "./fragments";

export class IntervalExportClass {
  IdEventType: number;
  DeEventType: string;
  IsActive: boolean;
  CodeStatus: string;

  constructor(i: IntervalModel) {
    this.IdEventType = i.IdEventType;
    this.DeEventType = i.DeEventType;
    this.IsActive = i.IsActive;
    this.CodeStatus = i.CodeStatus;
  }
}

export type IntervalModel = {
  IdEventType: number;
  DeEventType: string;
  IsActive: boolean;
  IdProject: number;
  CodeStatus: string;
};

export type IntervalOptModel = {
  IdEventType: number;
  NmEventType: string;
  DeEventType: string;
  IsPause: boolean;
};

// Requests

export type GetIntervalsRequest = {
  pIdProject: number;
  pDeEventType?: string;
};

export type GetIntervalOptsRequest = {
  pIdProject: number;
};

export type ManageIntervalRequest = {
  pIdProject: number;
  pIdEventType: number;
  pIsActive: boolean;
  pDeEventType: string;
  pCodeStatus: number;
};

export type SelectIntervalRequest = {
  pIdEventType: number;
};

// Responses

export type GetIntervalsResponse = BaseReponseType & {
  eventTypePauseList: IntervalModel[];
};

export type GetIntervalOptsResponse = BaseReponseType & {
  eventTypeList: IntervalOptModel[];
};

export type ManageIntervalResponse = BaseReponseType;

export type SelectIntervalResponse = BaseReponseType;
