import { useMap } from "hooks";
import { BaseProviderType, PermissionProviderType, ProductModel } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as ProductService from "services/product";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, ProductModel> | undefined;

type ProductContextType = {
  loading: boolean;
  products?: ProductModel[];
  activeProducts?: ProductModel[];
  getProducts: () => Promise<void>;
  addProduct: (key: number, value: ProductModel) => Promise<UndefinedMap>;
  editProduct: (key: number, value: ProductModel) => Promise<UndefinedMap>;
  removeProduct: (key: number) => Promise<UndefinedMap>;
};

const ProductContext = createContext({} as ProductContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, ProductModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const products = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeProducts = useMemo(() => products?.filter((c) => c), [products]);

  const getProducts = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await ProductService.getProducts({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.productList.map((p) => [p.IdProduct, p])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <ProductContext.Provider
      value={{
        products,
        activeProducts,
        loading,
        getProducts,
        addProduct: add,
        editProduct: edit,
        removeProduct: remove,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const ProductProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useProduct = () => useContext(ProductContext);
