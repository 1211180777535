import { DivisorContent, DivisorLine, DivisorStyleType } from "./styles";

type DivisorType = DivisorStyleType & { children?: string };

const Divisor = ({ children, ...args }: DivisorType) => {
  if (children)
    return (
      <DivisorContent {...args}>
        <DivisorLine />
        <p>{children}</p>
        <DivisorLine />
      </DivisorContent>
    );

  return (
    <DivisorContent {...args}>
      <DivisorLine />
    </DivisorContent>
  );
};

export default Divisor;
