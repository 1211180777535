import {
  AuthProvider,
  DialogProvider,
  ProfileProvider,
  ToastProvider,
} from "providers";
import GlobalStyle from "./global.styles";
import RoutesMap from "./routes";

const App = () => {
  return (
    <ToastProvider position="bottom-right" theme="light">
      <AuthProvider>
        <ProfileProvider>
          <DialogProvider>
            <RoutesMap />
          </DialogProvider>
          <GlobalStyle />
        </ProfileProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default App;
