import { notepadPath, popupSize, projectKey, ss } from "consts";
import SelectProject from "modals/selectProject";
import { BaseProviderType, MyUserModel, ProjectOptModel } from "models";
import { useAuth } from "providers/auth";
import { useToast } from "providers/toast";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as ListService from "services/list";
import * as UserService from "services/user";
import { aytyFormatError, objectToURLParams, sortBy } from "utils";
import { ProfileErrors } from "./errors";

const adminCodeProject = 3;

let notepadWindow: Window | null;

type ProfileContextType = {
  user?: MyUserModel;
  isSagoCore: boolean;
  projectOpts?: ProjectOptModel[];
  pIdProject?: number;
  selectedProject?: ProjectOptModel;
  notepadWindow: Window | null;
  getProjectOpts: () => Promise<void>;
  deleteUser: () => void;
  handleOpenSelect: () => void;
  handleChangePIdProject: (newId: number) => void;
  handleNotepad: () => void;
};

const ProfileContext = createContext<ProfileContextType>(
  {} as ProfileContextType
);

export const ProfileProvider = ({ children }: BaseProviderType) => {
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState<MyUserModel>();
  const [projectOpts, setProjectOpts] = useState<ProjectOptModel[]>();
  const [pIdProject, setPIdProject] = useState<number>();
  const { token } = useAuth();
  const { error, warning } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const errorsResolver = useMemo(
    () => new ProfileErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isSagoCore = useMemo(
    () => pIdProject === adminCodeProject,
    [pIdProject]
  );

  const selectedProject = useMemo(
    () => projectOpts?.find((p) => p.IdProject === pIdProject),
    [projectOpts, pIdProject]
  );

  const handleOpenSelect = useCallback(() => setModal(true), []);

  useEffect(() => {
    const currentId = ss.getItem(projectKey);
    if (projectOpts?.length) {
      if (currentId) setPIdProject(Number(currentId));
      else if (projectOpts.length === 1)
        setPIdProject(projectOpts[0].IdProject);
      else handleOpenSelect();
    }
  }, [projectOpts, handleOpenSelect]);

  useEffect(() => {
    if (user) {
      const { hasAccessAttend, mustLoginPaused } = user;
      const hasPermission = hasAccessAttend && !mustLoginPaused;
      const isNotAdmin = pIdProject && !isSagoCore;
      const isBasePath = window.location.pathname === "/";
      if (hasPermission && isNotAdmin && isBasePath) navigate("/attendance");
    }
    // ToDo: Remove navigate dependence
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pIdProject, user, isSagoCore]);

  const getProjectOpts = useCallback(async () => {
    if (token)
      await ListService.getProjectOpts({})
        .then(({ data }) => {
          if (data.idReturnAPI > 0) {
            let adminProject: ProjectOptModel | undefined;
            let current: ProjectOptModel[] = [];

            data.projectList?.forEach((p) => {
              if (p.IsActive) {
                if (p.IdProject === adminCodeProject) adminProject = p;
                else current.push(p);
              }
            });

            current = sortBy(current, "DeProject");
            setProjectOpts(adminProject ? [adminProject, ...current] : current);
          } else warning({ description: t(aytyFormatError(data)) });
        })
        .catch(errorsResolver.defaultError);
  }, [token, errorsResolver, warning, t]);

  useEffect(() => {
    getProjectOpts();
  }, [getProjectOpts]);

  const getUser = useCallback(async () => {
    if (pIdProject)
      await UserService.getMe({ pIdProject })
        .then(({ data }) => {
          const { dtServer, deReturnAPI, idReturnAPI, ...args } = data;
          if (idReturnAPI > 0) setUser(args);
          else warning({ description: t(aytyFormatError(data)) });
        })
        .catch(errorsResolver.defaultError);
  }, [pIdProject, errorsResolver, warning, t]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const deleteUser = useCallback(() => {
    notepadWindow?.close();
    setProjectOpts(undefined);
    setPIdProject(undefined);
    setUser(undefined);
  }, []);

  const handleChangePIdProject = useCallback((newId: number) => {
    setUser(undefined);
    setPIdProject(newId);
    ss.setItem(projectKey, newId.toString());
  }, []);

  const handleNotepad = useCallback(() => {
    if (notepadWindow?.closed === false) notepadWindow.focus();
    else {
      const { x, y } = popupSize;
      const left = window.screen.width / 2 - x / 2;
      const top = window.screen.height / 2 - y / 2;

      notepadWindow = window.open(
        `${notepadPath}?${objectToURLParams({ token })}`,
        "Notepad",
        `noopener=true,width=${x},height=${y},left=${left},top=${top}`
      );
    }
  }, [token]);

  return (
    <ProfileContext.Provider
      value={{
        user,
        isSagoCore,
        projectOpts,
        pIdProject,
        selectedProject,
        notepadWindow,
        getProjectOpts,
        deleteUser,
        handleOpenSelect,
        handleChangePIdProject,
        handleNotepad,
      }}
    >
      <SelectProject open={modal} onClose={() => setModal(false)} />
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
