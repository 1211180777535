import { useHeader } from "providers";
import { Children, cloneElement, isValidElement } from "react";
import { ActionsContent, HeaderContainer, InfoContent } from "./styles";

const Header = () => {
  const { header } = useHeader();

  if (!header) return <></>;

  return (
    <HeaderContainer>
      {header.icon}
      <InfoContent>
        <h1>{header.title}</h1>
        {header.description && <p>{header.description}</p>}
      </InfoContent>
      {header.actions && (
        <ActionsContent>
          {Children.map(header.actions, (child, index) => {
            if (isValidElement(child))
              return cloneElement(child, { key: index });
          })}
        </ActionsContent>
      )}
    </HeaderContainer>
  );
};

export default Header;
