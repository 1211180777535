// Models

import { BaseReponseType } from "models";

export class QueueExportClass {
  IdAttendGroup: number;
  NmAttendGroup: string;
  DeProject: string;
  IsActive: boolean;
  NmChannelType: string;
  IdChannelType: number;
  NmOmniSource: string;
  IdOmniSource: number;
  HrDsStart1: string;
  HrDsFinish1: string;
  HrDsStartSaturday1: string;
  HrDsFinishSaturday1: string;
  HrDsStartSunday1: string;
  HrDsFinishSunday1: string;
  QtMaxOmnisActives: string;

  constructor(q: QueueModel) {
    this.IdAttendGroup = q.IdAttendGroup;
    this.NmAttendGroup = q.NmAttendGroup;
    this.DeProject = q.DeProject;
    this.IsActive = q.IsActive;
    this.NmChannelType = q.NmChannelType;
    this.IdChannelType = q.IdChannelType;
    this.NmOmniSource = q.NmOmniSource;
    this.IdOmniSource = q.IdOmniSource;
    this.HrDsStart1 = q.HrDsStart1;
    this.HrDsFinish1 = q.HrDsFinish1;
    this.HrDsStartSaturday1 = q.HrDsStartSaturday1;
    this.HrDsFinishSaturday1 = q.HrDsFinishSaturday1;
    this.HrDsStartSunday1 = q.HrDsStartSunday1;
    this.HrDsFinishSunday1 = q.HrDsFinishSunday1;
    this.QtMaxOmnisActives = q.QtMaxOmnisActives;
  }
}

export type QueueModel = {
  IdAttendGroup: number;
  NmAttendGroup: string;
  CodeAttendGroup: string;
  DeProject: string;
  IsActive: boolean;
  NmChannelType: string;
  IdChannelType: number;
  NmOmniSource: string;
  IdOmniSource: number;
  HrDsStart1: string;
  HrDsFinish1: string;
  HrDsStartSaturday1: string;
  HrDsFinishSaturday1: string;
  HrDsStartSunday1: string;
  HrDsFinishSunday1: string;
  QtMaxOmnisActives: string;
  NmAttendGroupToDisplay: string;
};

// Requests

export type GetQueuesRequest = {
  pIdProject: number;
};

export type ManageQueueRequest = {
  pIdAttendGroup: number;
  pNmAttendGroup: string;
  pIdProject: number;
  pIsActive: boolean;
  pIdChannelType: number;
  pIdOmniSource: number;
  pHrDsStart1: string;
  pHrDsFinish1: string;
  pHrDsStartSaturday1: string;
  pHrDsFinishSaturday1: string;
  pHrDsStartSunday1: string;
  pHrDsFinishSunday1: string;
  pQtMaxOmnisActives: string;
};

export type ManageUserQueueRequest = {
  pIdProject: number;
  pIdAction: number;
  pIdUser: number[];
  pIdAttendGroup: number[];
  pVlPriority: number;
};

// Responses

export type GetQueuesResponse = BaseReponseType & {
  attendGroupList: QueueModel[];
};

export type ManageQueueResponse = BaseReponseType;

export type ManageUserQueueResponse = BaseReponseType;
