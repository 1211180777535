import { useMap } from "hooks";
import {
  BaseProviderType,
  PermissionProviderType,
  StatusGroupModel,
  StatusModel,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as StatusService from "services/status";
import { DataErrors } from "./errors";

type StatusMap = Map<number, StatusModel> | undefined;

type StatusGroupMap = Map<number, StatusGroupModel> | undefined;

type StatusContextType = {
  loading: boolean;
  status?: StatusModel[];
  activeStatus?: StatusModel[];
  statusGroups?: StatusGroupModel[];
  activeStatusGroups?: StatusGroupModel[];
  getStatus: () => Promise<void>;
  addStatus: (key: number, value: StatusModel) => Promise<StatusMap>;
  editStatus: (key: number, value: StatusModel) => Promise<StatusMap>;
  removeStatus: (key: number) => Promise<StatusMap>;
  addStatusGroup: (
    key: number,
    value: StatusGroupModel
  ) => Promise<StatusGroupMap>;
  editStatusGroup: (
    key: number,
    value: StatusGroupModel
  ) => Promise<StatusGroupMap>;
  removeStatusGroup: (key: number) => Promise<StatusGroupMap>;
};

const StatusContext = createContext({} as StatusContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [statusList, statusAction] = useMap<number, StatusModel>();
  const [groupList, groupAction] = useMap<number, StatusGroupModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const status = useMemo(() => {
    if (statusList) return Array.from(statusList.values());
  }, [statusList]);

  const activeStatus = useMemo(
    () => status?.filter((c) => c.IsActive),
    [status]
  );

  const statusGroups = useMemo(() => {
    if (groupList) return Array.from(groupList.values());
  }, [groupList]);

  const activeStatusGroups = useMemo(
    () => statusGroups?.filter((c) => c.IsActive),
    [statusGroups]
  );

  const getStatus = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await Promise.all([
        StatusService.getStatus({ pIdProject }).then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            statusAction.set(
              new Map(data.statusList.map((s) => [s.IdStatus, s]))
            );
          else warning({ description: t("errors.failedToFetchList") });
        }),
        StatusService.getStatusGroup({ pIdProject }).then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            groupAction.set(
              new Map(data.statusGroupList.map((s) => [s.IdStatusGroup, s]))
            );
          else warning({ description: t("errors.failedToFetchList") });
        }),
      ]).catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, statusAction, groupAction, warning, t]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  return (
    <StatusContext.Provider
      value={{
        status,
        activeStatus,
        statusGroups,
        activeStatusGroups,
        loading,
        getStatus,
        addStatus: statusAction.add,
        editStatus: statusAction.edit,
        removeStatus: statusAction.remove,
        addStatusGroup: groupAction.add,
        editStatusGroup: groupAction.edit,
        removeStatusGroup: groupAction.remove,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const StatusProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useStatus = () => useContext(StatusContext);
