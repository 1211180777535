import { AxiosRequestConfig } from "axios";
import {
  GetQueuesRequest,
  GetQueuesResponse,
  ManageQueueRequest,
  ManageQueueResponse,
  ManageUserQueueRequest,
  ManageUserQueueResponse,
} from "models";
import service from "services";

// Manage queue
export const getQueues = async (
  params: GetQueuesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetQueuesResponse>("AttendGroup/QueryAttendGroup", {
    ...config,
    params,
  });

export const manageQueue = async (
  params: ManageQueueRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageQueueResponse>("AttendGroup/ManagerAttendGroup", {
    ...config,
    params,
  });

// Manage user queue
export const manageUserQueue = async (
  params: ManageUserQueueRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageUserQueueResponse>(
    "AttendGroup/ManagerAttendGroupUser",
    {
      ...config,
      params,
      paramsSerializer: { indexes: null },
    }
  );

// Manage campaign queue
