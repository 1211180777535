// Models

import { BaseReponseType, ControlTypeEnum, FormFieldModel } from "models";

export enum ControlTypeIDEnum {
  ComboBoxDropDown = 1,
  MaskedTextBox = 2,
  TextBox = 3,
  Button = 4,
  Label = 5,
  CheckBox = 6,
  Grid = 7,
}

export class FieldExportClass {
  IdBind: number;
  IdControlType: ControlTypeIDEnum;
  NmControlType: string;
  DeCaption: string;
  IdDbType: number;
  NmDbType: string;
  NmColumn: string;
  NmForeignKeyTable: string;
  NmForeignKeyOrderBy: string;
  NmForeignKeyDataTextField: string;
  NmForeignKeyDataValueField: string;
  NuOrder: number;
  IsActive: boolean;
  NuControlWidth: number;
  ControlColorRGB: string;
  RegexToValidate: string;
  DeMessageValidateByRegex: string;
  IdContext: number;
  DeContext: string;
  Mask: string;
  MaxLength: number;
  MustBeFilled: boolean;
  MustFilterByIdProject: boolean;
  MustBlockEditData: boolean;
  IdCrmConfiguration: number;

  constructor(f: FieldModel) {
    this.IdBind = f.IdBind;
    this.IdControlType = f.IdControlType;
    this.NmControlType = f.NmControlType;
    this.DeCaption = f.DeCaption;
    this.IdDbType = f.IdDbType;
    this.NmDbType = f.NmDbType;
    this.NmColumn = f.NmColumn;
    this.NmForeignKeyTable = f.NmForeignKeyTable;
    this.NmForeignKeyOrderBy = f.NmForeignKeyOrderBy;
    this.NmForeignKeyDataTextField = f.NmForeignKeyDataTextField;
    this.NmForeignKeyDataValueField = f.NmForeignKeyDataValueField;
    this.NuOrder = f.NuOrder;
    this.IsActive = f.IsActive;
    this.NuControlWidth = f.NuControlWidth;
    this.ControlColorRGB = f.ControlColorRGB;
    this.RegexToValidate = f.RegexToValidate;
    this.DeMessageValidateByRegex = f.DeMessageValidateByRegex;
    this.IdContext = f.IdContext;
    this.DeContext = f.DeContext;
    this.Mask = f.Mask;
    this.MaxLength = f.MaxLength;
    this.MustBeFilled = f.MustBeFilled;
    this.MustFilterByIdProject = f.MustFilterByIdProject;
    this.MustBlockEditData = f.MustBlockEditData;
    this.IdCrmConfiguration = f.IdCrmConfiguration;
  }
}

export type FieldModel = {
  IdBind: number;
  IdControlType: ControlTypeIDEnum;
  DeControlType: ControlTypeEnum;
  NmControlType: string;
  DeCaption: string;
  IdDbType: number;
  NmDbType: string;
  NmColumn: string;
  NmForeignKeyTable: string;
  NmForeignKeyOrderBy: string;
  NmForeignKeyDataTextField: string;
  NmForeignKeyDataValueField: string;
  IsForeignKey: boolean;
  NuOrder: number;
  IsActive: boolean;
  NuControlWidth: number;
  ControlColorRGB: string;
  RegexToValidate: string;
  DeMessageValidateByRegex: string;
  IdContext: number;
  DeContext: string;
  Mask: string;
  MaxLength: number;
  MustBeFilled: boolean;
  MustFilterByIdProject: boolean;
  MustBlockEditData: boolean;
  IdCrmConfiguration: number;
};

export class FormExportClass {
  IdCrmConfiguration: number;
  NmCrmConfiguration: string;
  IsActive: boolean;

  constructor(f: FormModel) {
    this.IdCrmConfiguration = f.IdCrmConfiguration;
    this.NmCrmConfiguration = f.NmCrmConfiguration;
    this.IsActive = f.IsActive;
  }
}

export type FormModel = {
  IdCrmConfiguration: number;
  NmCrmConfiguration: string;
  IsActive: boolean;
  IdProject: number;
};

// Requests

export type DeleteFieldRequest = {
  pIdBind: number;
};

export type GetFieldsRequest = {
  pIdProject: number;
  pIdCRMConfiguration: number;
};

export type GetFieldsByFormRequest = {
  pIdCRMConfiguration: number;
};

export type GetFormsRequest = {
  pIdProject: number;
  pNmCrmConfiguration?: string;
};

export type ManageFieldRequest = {
  pIdBind: number;
  pIdCrmConfiguration: number;
  pDeCaption: string;
  pIdContext: number;
  pIdControlType: number;
  pNmColumn: string;
  pIdDbType: number;
  pControlColorRGB: string;
  pMustBeFilled: boolean;
  pRegexToValidate: string;
  pDeMessageValidateByRegex: string;
  pMask: string;
  pMaxLength: number;
  pNuControlWidth: number;
  pNmForeignKeyTable: string;
  pNmForeignKeyDataVlField: string;
  pNmForeignKeyDataTextField: string;
  pNmForeignKeyOrderBy: string;
  pMustFilterByIdProject: boolean;
  pMustBlockEditData: boolean;
  pNuOrder: number;
  pIsActive: boolean;
};

export type ManageFormRequest = {
  pIdProject: number;
  pIdCrmConfiguration: number;
  pNmCrmConfiguration: string;
  pIsActive: boolean;
};

// Responses

export type DeleteFieldResponse = BaseReponseType & {
  idBind: number;
};

export type GetFieldsResponse = BaseReponseType & {
  bindListReturn: FieldModel[];
};

export type GetFieldsByFormResponse = BaseReponseType & {
  bindListCustomer: FormFieldModel[];
};

export type GetFormsResponse = BaseReponseType & {
  crmConfigurationList: FormModel[];
};

export type ManageFieldResponse = BaseReponseType & {
  idBind: number;
};

export type ManageFormResponse = BaseReponseType & {
  idCrmConfiguration: number;
};
