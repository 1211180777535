import { useMap } from "hooks";
import { BaseProviderType, PermissionProviderType, ProjectModel } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as ProjectService from "services/project";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, ProjectModel> | undefined;

type ProjectContextType = {
  loading: boolean;
  projects?: ProjectModel[];
  activeProjects?: ProjectModel[];
  getProjects: () => Promise<void>;
  addProject: (key: number, value: ProjectModel) => Promise<UndefinedMap>;
  editProject: (key: number, value: ProjectModel) => Promise<UndefinedMap>;
  removeProject: (key: number) => Promise<UndefinedMap>;
};

const ProjectContext = createContext({} as ProjectContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, ProjectModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const projects = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeProjects = useMemo(
    () => projects?.filter((o) => o.IsActive),
    [projects]
  );

  const getProjects = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await ProjectService.getProjects()
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.projectList.map((o) => [o.IdProject, o])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        activeProjects,
        loading,
        getProjects,
        addProject: add,
        editProject: edit,
        removeProject: remove,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const ProjectProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useProject = () => useContext(ProjectContext);
