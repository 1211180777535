import { BaseProviderType } from "models";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type HeaderType = {
  icon?: ReactNode;
  title?: string;
  description?: string;
  actions?: ReactNode[];
};

type HeaderContextType = {
  header?: HeaderType;
  setHeader: Dispatch<SetStateAction<HeaderType | undefined>>;
};

type HeaderProviderType = BaseProviderType & {
  defaultHeader?: HeaderType;
};

const HeaderContext = createContext({} as HeaderContextType);

export const HeaderProvider = ({
  children,
  defaultHeader,
}: HeaderProviderType) => {
  const [header, setHeader] = useState(defaultHeader);

  return (
    <HeaderContext.Provider
      value={{
        header,
        setHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);
