import { FormEventHandler, forwardRef, ReactNode } from "react";
import { Container, FormContent, FormstyleType, TitleForm } from "./styles";

type FormType = FormstyleType & {
  children: ReactNode;
  title?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};

const Form = forwardRef<HTMLFormElement, FormType>(
  ({ children, title, onSubmit, direction, spacing, ...args }, ref) => {
    return (
      <Container {...args}>
        {title && <TitleForm>{title}</TitleForm>}
        <FormContent
          ref={ref}
          spacing={spacing}
          direction={direction}
          onSubmit={(e) => onSubmit?.(e)}
        >
          {children}
        </FormContent>
      </Container>
    );
  }
);

export default Form;
