import { AxiosRequestConfig } from "axios";
import {
  GetProductsRequest,
  GetProductsResponse,
  ManageProductRequest,
  ManageProductResponse,
} from "models";
import service from "services";

// Manage product
export const getProducts = async (
  params: GetProductsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetProductsResponse>("Product/QueryProduct", {
    ...config,
    params,
  });

export const manageProduct = async (
  params: ManageProductRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageProductResponse>("Product/ManagerProduct", {
    ...config,
    params,
  });
