import { Navigate } from "react-router-dom";

type RequirePermissionType = {
  children: JSX.Element;
  permission?: boolean;
};

const RequirePermission = ({
  children,
  permission = true,
}: RequirePermissionType) => {
  if (!permission) return <Navigate to="/" />;

  return children;
};

export default RequirePermission;
