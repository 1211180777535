import { AxiosRequestConfig } from "axios";
import {
  GetCampaignTypesRequest,
  GetCampaignTypesResponse,
  GetCampaignsRequest,
  GetCampaignsResponse,
  ManageCampaignRequest,
  ManageCampaignResponse,
  ManageCampaignTypeRequest,
  ManageCampaignTypeResponse,
} from "models";
import service from "services";

// Manage campaign
export const getCampaigns = async (
  params: GetCampaignsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetCampaignsResponse>("Campaign/QueryCampaign", {
    ...config,
    params,
  });

export const manageCampaign = async (
  params: ManageCampaignRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageCampaignResponse>("Campaign/ManagerCampaign", {
    ...config,
    params,
  });

// Manage campaign type
export const getCampaignTypes = async (
  params: GetCampaignTypesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetCampaignTypesResponse>(
    "CampaignType/QueryCampaignType",
    { ...config, params }
  );

export const manageCampaignType = async (
  params: ManageCampaignTypeRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageCampaignTypeResponse>(
    "CampaignType/ManagerCampaignType",
    { ...config, params }
  );
