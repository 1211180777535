import { ButtonColorType } from "components/button";
import Dialog from "components/dialog";
import { BaseProviderType } from "models";
import { createContext, useCallback, useContext, useState } from "react";

type DialogDataType = {
  title: string;
  description: string;
  confirmColor?: ButtonColorType;
  callback: () => void;
};

type DialogContextType = {
  confirm: (data: DialogDataType) => void;
};

const DialogContext = createContext<DialogContextType>({} as DialogContextType);

export const DialogProvider = ({ children }: BaseProviderType) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<DialogDataType>();

  const confirm = useCallback((data: DialogDataType) => {
    setData(data);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(undefined);
  }, []);

  const handleConfirm = useCallback(() => {
    data?.callback?.();
    handleClose();
  }, [data, handleClose]);

  return (
    <DialogContext.Provider value={{ confirm }}>
      {children}
      {data && (
        <Dialog
          show={open}
          title={data?.title}
          description={data?.description}
          confirmColor={data?.confirmColor}
          onConfirm={handleConfirm}
          onCancel={handleClose}
        />
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
