export const validAlphanumeric = /^[a-zA-Z0-9]+$/;

export const validCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export const validCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

export const validFilename = /^[0-9a-z_-]{3,255}\.([a-z0-9]{2,5})$/;

export const validPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,}$/;

export const validPhone =
  /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;

export const validZipCode = /^[0-9]{5}-[0-9]{3}$/;
