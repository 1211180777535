import Avatar from "components/avatar";
import IconButton from "components/iconButton";
import styled from "styled-components";

type MenuStyleType = {
  $isCollapsed: boolean;
};

type ProfileAvatarStyleType = {
  $inPause?: boolean;
};

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const SideBarContainer = styled.header<MenuStyleType>`
  height: 100%;
  width: ${({ $isCollapsed }) => ($isCollapsed ? "7.2rem" : "24.6rem")};
  position: relative;
  transition: width ease-in-out 0.5s;

  &:hover > .collapse-button {
    opacity: 1;
    pointer-events: initial;
  }

  > div {
    height: 100%;
    max-height: 100%;
    overflow: ${({ $isCollapsed }) => ($isCollapsed ? "initial" : "auto")};
    background: var(--neutral);
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    a,
    a:hover,
    a:focus {
      text-decoration: none;
    }

    > svg {
      max-width: 100%;
      min-height: 4rem;
    }
  }
`;

export const Nav = styled.nav`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  a {
    text-decoration: none;
  }
`;

export const NavButton = styled(IconButton).attrs({
  outline: true,
  className: "collapse-button",
})<MenuStyleType>`
  z-index: 15;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-in-out 0.2s;

  position: absolute;
  top: 2.4rem;
  right: -1.2rem;
  transform: ${({ $isCollapsed }) =>
    $isCollapsed ? "rotate(180deg)" : "initial"};

  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid var(--gray);
  background: var(--white);
  color: var(--text);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileAvatar = styled(Avatar)<ProfileAvatarStyleType>`
  width: 3.6rem;
  height: 3.6rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--white);
    background-color: ${({ $inPause }) =>
      $inPause ? "var(--red)" : "var(--green)"};
  }
`;

export const LayoutContent = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
`;

export const PauseAlert = styled.section`
  width: 100%;
  padding: 0.4rem 1.6rem;
  background-color: var(--red-light-100);
  color: var(--red-light-600);
  position: sticky;
  top: 0;
  z-index: 10;

  &,
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;
  }

  > div {
    p {
      padding: 0.2rem 0.8rem;
      border-radius: 0.4rem;
      border: 0.1rem solid currentColor;
      font-weight: bold;
    }

    span {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    h3 {
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: bold;
      color: currentColor;
    }
  }
`;

export const HeaderContainer = styled.section`
  width: 100%;
  padding: 1.6rem 2.4rem;
  background-color: var(--white);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;

  border-bottom: 0.1rem solid var(--gray);

  h1 {
    font-size: 2.4rem;
    line-height: 4rem;
    color: var(--text-title);
  }
`;

export const InfoContent = styled.div`
  flex: 1;
`;

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const ContentContainer = styled.section`
  width: 100%;
  flex: 1;
`;
