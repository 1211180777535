import { useAuth } from "providers";
import { Navigate, useLocation } from "react-router-dom";

type RequireAuthType = { children: JSX.Element };

const RequireAuth = ({ children }: RequireAuthType) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/authentication" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
