// Models

import { BaseReponseType } from "models";

export class FAQExportClass {
  IdOmniFAQ: number;
  DeQuestion: string;
  DeAnswer: string;
  IsActive: boolean;

  constructor(f: FAQModel) {
    this.IdOmniFAQ = f.IdOmniFAQ;
    this.DeQuestion = f.DeQuestion;
    this.DeAnswer = f.DeAnswer;
    this.IsActive = f.IsActive;
  }
}

export type FAQModel = {
  IdOmniFAQ: number;
  DeQuestion: string;
  DeAnswer: string;
  IsActive: boolean;
  IdProject: number;
  IdOmniSource: number;
  NmOmniSource: string;
};

// Requests

export type GetFAQsRequest = {
  pIdProject: number;
  pDeQuestion?: string;
  pDeAnswer?: string;
};

export type ManageFAQRequest = {
  pIdOmniFAQ: number;
  pIdProject: number;
  pDeQuestion: string;
  pDeAnswer: string;
  pIsActive: boolean;
};

// Responses

export type GetFAQsResponse = BaseReponseType & {
  omniFAQList: FAQModel[];
};

export type ManageFAQResponse = BaseReponseType & {
  idOmniFAQ: number;
};
