import { AxiosRequestConfig } from "axios";
import {
  GetProjectsResponse,
  ManageProjectRequest,
  ManageProjectResponse,
} from "models";
import service from "services";

// Manage project
export const getProjects = async (config?: AxiosRequestConfig) =>
  await service.get<GetProjectsResponse>("Project/QueryProject", config);

export const manageProject = async (
  params: ManageProjectRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageProjectResponse>("Project/ManagerProject", {
    ...config,
    params,
  });
