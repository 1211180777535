import { useMap } from "hooks";
import { BaseProviderType, FormModel, PermissionProviderType } from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as FormService from "services/form";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, FormModel> | undefined;

type FormContextType = {
  loading: boolean;
  forms?: FormModel[];
  activeForms?: FormModel[];
  getForms: () => Promise<void>;
  addForm: (key: number, value: FormModel) => Promise<UndefinedMap>;
  editForm: (key: number, value: FormModel) => Promise<UndefinedMap>;
  removeForm: (key: number) => Promise<UndefinedMap>;
};

const FormContext = createContext({} as FormContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, FormModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const forms = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeForms = useMemo(() => forms?.filter((c) => c.IsActive), [forms]);

  const getForms = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await FormService.getForms({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(
              new Map(
                data.crmConfigurationList.map((c) => [c.IdCrmConfiguration, c])
              )
            );
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getForms();
  }, [getForms]);

  return (
    <FormContext.Provider
      value={{
        forms,
        activeForms,
        loading,
        getForms,
        addForm: add,
        editForm: edit,
        removeForm: remove,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const FormProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useFormCRM = () => useContext(FormContext);
