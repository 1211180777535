import { useMap } from "hooks";
import {
  BaseProviderType,
  CampaignModel,
  PermissionProviderType,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as CampaignService from "services/campaign";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, CampaignModel> | undefined;

type CampaignContextType = {
  loading: boolean;
  campaigns?: CampaignModel[];
  activeCampaigns?: CampaignModel[];
  getCampaigns: () => Promise<void>;
  addCampaign: (key: number, value: CampaignModel) => Promise<UndefinedMap>;
  editCampaign: (key: number, value: CampaignModel) => Promise<UndefinedMap>;
  removeCampaign: (key: number) => Promise<UndefinedMap>;
};

const CampaignContext = createContext({} as CampaignContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, CampaignModel>();
  const { pIdProject } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const campaigns = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeCampaigns = useMemo(
    () => campaigns?.filter((c) => c.IsActive),
    [campaigns]
  );

  const getCampaigns = useCallback(async () => {
    setLoading(true);
    if (pIdProject)
      await CampaignService.getCampaigns({ pIdProject })
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            set(new Map(data.campaignList.map((c) => [c.IdCampaign, c])));
          else warning({ description: t("errors.failedToFetchList") });
        })
        .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return (
    <CampaignContext.Provider
      value={{
        campaigns,
        activeCampaigns,
        loading,
        getCampaigns,
        addCampaign: add,
        editCampaign: edit,
        removeCampaign: remove,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const CampaignProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useCampaign = () => useContext(CampaignContext);
