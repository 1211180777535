import { AxiosRequestConfig } from "axios";
import {
  GetStatusByCampaignRequest,
  GetStatusByCampaignResponse,
  GetStatusGroupRequest,
  GetStatusGroupResponse,
  GetStatusRequest,
  GetStatusResponse,
  ManageStatusByCampaignRequest,
  ManageStatusByCampaignResponse,
  ManageStatusGroupRequest,
  ManageStatusGroupResponse,
  ManageStatusRequest,
  ManageStatusResponse,
} from "models";
import service from "services";

// Manage status
export const getStatus = async (
  params: GetStatusRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusResponse>("Status/QueryStatus", {
    ...config,
    params,
  });

export const getStatusByCampaign = async (
  params: GetStatusByCampaignRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusByCampaignResponse>(
    "Status/QueryStatusSystemContext",
    { ...config, params }
  );

export const getStatusGroup = async (
  params: GetStatusGroupRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetStatusGroupResponse>("Status/QueryStatusGroup", {
    ...config,
    params,
  });

export const manageStatus = async (
  params: ManageStatusRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageStatusResponse>("Status/ManagerStatus", {
    ...config,
    params,
  });

export const manageStatusByCampaign = async (
  params: ManageStatusByCampaignRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageStatusByCampaignResponse>(
    "Status/ManagerStatusSystemContext",
    { ...config, params }
  );

export const manageStatusGroup = async (
  params: ManageStatusGroupRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageStatusGroupResponse>("Status/ManagerStatusGroup", {
    ...config,
    params,
  });
