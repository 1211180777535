import { useSet } from "hooks";
import { useCallback, useMemo } from "react";

export const useSelect = <T extends any>(initialValue?: T[]) => {
  const [selected, { set: setSelected, add, remove, clear }] =
    useSet<T>(initialValue);

  const set = useCallback(
    (list?: T[]) => setSelected(new Set(list)),
    [setSelected]
  );

  const hasAll = useCallback(
    (all?: T[]) => !!all?.length && all?.every((a) => selected?.has(a)),
    [selected]
  );

  const selectAll = useCallback(
    (isChecked: boolean, list?: T[]) => {
      if (isChecked) setSelected(new Set(list));
      else clear();
    },
    [setSelected, clear]
  );

  const selectItem = useCallback(
    (isChecked: boolean, item: T) => {
      if (isChecked) add(item);
      else remove(item);
    },
    [remove, add]
  );

  const actions = useMemo(
    () => ({ set, clear, hasAll, selectAll, selectItem }),
    [set, clear, hasAll, selectAll, selectItem]
  );

  return [selected, actions] as const;
};
