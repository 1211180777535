import OverflowText from "components/overflowText";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import { exitElement, sortBy } from "utils";
import {
  ArrowDonwIcon,
  Container,
  Header,
  IconContent,
  InfoContainer,
  Item,
  ItemContent,
  LabelContent,
  NavDropdownStyleType,
} from "./styles";

type itemMenuType = {
  label: string;
  path?: string;
  icon?: ReactNode;
  action?: () => void;
};

type NavDropdownType = Omit<NavDropdownStyleType, "$open"> & {
  label?: string;
  text: string;
  items: itemMenuType[];
  icon: ReactNode;
  $disable?: boolean;
};

const NavDropdown = ({
  items,
  icon,
  label,
  text,
  $disable,
  $justIcon,
}: NavDropdownType) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLLIElement>(null);

  const sortedItems = useMemo(() => sortBy(items, "label"), [items]);

  const handleToggle = useCallback((open: boolean) => setOpen(open), []);

  useEffect(() => {
    const hide = exitElement(ref, () => $justIcon && setOpen(false));
    document.documentElement.addEventListener("click", hide);
    return () => document.documentElement.removeEventListener("click", hide);
  }, [$justIcon]);

  return (
    <Container ref={ref} title={label ? `${label}: ${text}` : text}>
      <Header $open={open} onClick={() => !$disable && handleToggle(!open)}>
        <IconContent>{icon}</IconContent>
        {!$justIcon && (
          <>
            <InfoContainer>
              {label && <LabelContent>{label}</LabelContent>}
              <OverflowText>{text}</OverflowText>
            </InfoContainer>
            <ArrowDonwIcon $open={open} />
          </>
        )}
      </Header>
      <ItemContent $open={open} $justIcon={$justIcon}>
        {sortedItems?.map((item, index) =>
          item.path ? (
            <NavLink
              key={index}
              to={item.path}
              onClick={() => $justIcon && handleToggle(false)}
            >
              {({ isActive }) => (
                <Item className={isActive ? "active" : undefined}>
                  <IconContent>{item.icon}</IconContent>
                  <OverflowText>{item.label}</OverflowText>
                </Item>
              )}
            </NavLink>
          ) : (
            <Item
              onClick={() => {
                $justIcon && handleToggle(false);
                item.action?.();
              }}
              key={index}
            >
              <IconContent>{item.icon}</IconContent>
              <OverflowText>{item.label}</OverflowText>
            </Item>
          )
        )}
      </ItemContent>
    </Container>
  );
};

export default NavDropdown;
