import { ReactNode } from "react";
import {
  Container,
  IconContent,
  Label,
  NavItemStyleType,
  Title,
  TitleContent,
} from "./styles";

type NavItemType = NavItemStyleType & {
  label?: string;
  name: string;
  icon: ReactNode;
  onClick?: () => void;
};

const NavItem = ({
  icon,
  label,
  name,
  $justIcon,
  $isActive,
  onClick,
}: NavItemType) => {
  return (
    <Container
      onClick={onClick}
      $hasLabel={!!label}
      $isActive={$isActive}
      $justIcon={$justIcon}
      title={label ? `${label}: ${name}` : name}
    >
      <IconContent $isActive={$isActive}>{icon}</IconContent>
      {!$justIcon && (
        <TitleContent>
          {label && <Label>{label}</Label>}
          <Title>{name}</Title>
        </TitleContent>
      )}
    </Container>
  );
};

export default NavItem;
