// Models

export enum QuestionTypeEnum {
  options = 1,
  text = 2,
}

export type QuestionModel = {
  IdQuestion: number;
  DeQuestion: string;
  IdQuestionType: QuestionTypeEnum;
  answerList: {
    IdAnswer: number;
    DeAnswer: string;
  }[];
};

// Requests

// Responses
