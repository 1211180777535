import styled, { css } from "styled-components";

export type DivisorStyleType = {
  retreat?: number;
};

export const DivisorContent = styled.section<DivisorStyleType>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  ${({ retreat }) =>
    retreat
      ? css`
          width: calc(100% + ${retreat * 2}rem);
          margin: 0 -${retreat}rem;
        `
      : css`
          width: 100%;
          margin: initial;
        `}
`;

export const DivisorLine = styled.hr`
  flex: 1;
  border: none;
  border-bottom: 0.1rem solid var(--gray);
`;
