// Models

import { BaseReponseType, SubChannelTypeEnum } from "models";

export class ChannelExportClass {
  IdOmniSource: number;
  NmOmniSource: string;
  DeMessageFinalizeAttend: string;
  DeMsgOmniCongrats: string;
  IdSubChannelType: number;
  IsActive: boolean;
  NuPhoneWhatsapp: string;
  CodeSocialMediaSource: string;
  DeWarningMessageAutoFinishOmni: string;
  QtSecWarningMessageAutoFinishOmni: number;
  DeMessageAutoFinishOmni: string;
  QtSecNoMessageAutoFinishOmni: number;
  HasFinalizeMetaWindow: boolean;
  DeFinalizeMetaWindow: string;
  IdCampaignType: number;
  NmCampaignType: string;
  IdBotCallbackResponses: string;
  QtMaxOmnisActives: string;

  constructor(c: ChannelModel) {
    this.IdOmniSource = c.IdOmniSource;
    this.DeMessageFinalizeAttend = c.DeMessageFinalizeAttend;
    this.DeMsgOmniCongrats = c.DeMsgOmniCongrats;
    this.IdSubChannelType = c.IdSubChannelType;
    this.IsActive = c.IsActive;
    this.NmOmniSource = c.NmOmniSource;
    this.NuPhoneWhatsapp = c.NuPhoneWhatsapp;
    this.CodeSocialMediaSource = c.CodeSocialMediaSource;
    this.DeWarningMessageAutoFinishOmni = c.DeWarningMessageAutoFinishOmni;
    this.QtSecWarningMessageAutoFinishOmni =
      c.QtSecWarningMessageAutoFinishOmni;
    this.DeMessageAutoFinishOmni = c.DeMessageAutoFinishOmni;
    this.QtSecNoMessageAutoFinishOmni = c.QtSecNoMessageAutoFinishOmni;
    this.HasFinalizeMetaWindow = c.HasFinalizeMetaWindow;
    this.DeFinalizeMetaWindow = c.DeFinalizeMetaWindow;
    this.IdCampaignType = c.IdCampaignType;
    this.NmCampaignType = c.NmCampaignType;
    this.IdBotCallbackResponses = c.IdBotCallbackResponses;
    this.QtMaxOmnisActives = c.QtMaxOmnisActives;
  }
}

export type ChannelModel = {
  IdOmniSource: number;
  NmOmniSource: string;
  NuPhoneWhatsapp: string;
  MustInsertMailingWhatsapp: boolean;
  IdCampaignDefaultWhatsapp: number;
  NmCampaignDefaultWhatsapp: string;
  NmMailingDefaultWhatsapp: string;
  IdSubChannelType: SubChannelTypeEnum;
  NmSubChannelType: string;
  IsActive: boolean;
  DeMsgOmniCongrats: string;
  DtStartMorning: string;
  DtStartAfternoon: string;
  DtStartNight: string;
  DeMsgGreetingsMorning: string;
  DeMsgGreetingsAfternoon: string;
  DeMsgGreetingsNight: string;
  CodeSocialMediaApi: string;
  DeUrlSocialMedia: string;
  CodeSocialMediaSource: string;
  DeMessageOutOfTime: string;
  DeMessageWithoutAgent: string;
  DeMessageInactivity: string;
  DeUrlWhatsapp: string;
  HasWhatsappBot: boolean;
  DeMessageChatQueue: string;
  IdCampaignDefaultChat: number;
  NmCampaignDefaultChat: string;
  DeMessageWait: string;
  QtMinutesMessageWait: number;
  DeMessageWillExpire: string;
  QtMinutesToExpire: string;
  QtSecIntervalMessageInactivity: string;
  MustHideOmniPendingOnCrm: boolean;
  DeMessageFinalizeAttend: string;
  QtSecWarningMessageAutoFinishOmni: number;
  DeWarningMessageAutoFinishOmni: string;
  QtSecNoMessageAutoFinishOmni: number;
  DeMessageAutoFinishOmni: string;
  HasFinalizeMetaWindow: boolean;
  DeFinalizeMetaWindow: string;
  IdCampaignType: number;
  NmCampaignType: string;
  IdBotCallbackResponses: string;
  QtMaxOmnisActives: string;
};

// Requests

export type GetChannelsRequest = {
  pIdProject: number;
  pNmOmniSource?: string;
};

export type ManageChannelRequest = {
  pIdProject: number;
  pIdOmniSource: number;
  pIdSubChannelType: number;
  pIsActive: boolean;
  pNmOmniSource: string;
  pNuPhoneWhatsapp: string;
  pCodeSocialMediaSource: string;
  pDeMsgOmniCongrats: string;
  pDeMessageFinalizeAttend: string;
  pQtSecNoMessageAutoFinishOmni: number;
  pDeMessageAutoFinishOmni: string;
  pQtSecWarningMessageAutoFinishOmni: number;
  pDeWarningMessageAutoFinishOmni: string;
  pQtMinutesMessageWait: string;
  pDeMessageWait: string;
  pHasFinalizeMetaWindow: boolean;
  pDeFinalizeMetaWindow: string;
  pIdCampaignType: number;
  pQtMaxOmnisActives: string;
};

// Responses

export type GetChannelsResponse = BaseReponseType & {
  omniSourceList: ChannelModel[];
};

export type ManageChannelResponse = BaseReponseType & {
  idOmniSource: number;
};
