import {
  allFormsKey,
  allHistoriesKey,
  allIntegrationsKey,
  allMsgsKey,
  allOrdersKey,
  allQuestionsKey,
  allTicketsKey,
  ss,
  tenSeconds,
} from "consts";
import { useMap } from "hooks";
import {
  AttendanceModel,
  AttendanceTicketModel,
  FormFieldModel,
  HistoricModel,
  IntegrationModel,
  MessageModel,
  OrderModel,
  PermissionProviderType,
  QuestionModel,
} from "models";
import { useAttendance, useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as AttendanceService from "services/attendance";
import { aytyFormatError } from "utils";
import { DataErrors } from "./errors";

type UndefinedMap = Map<number, MessageModel> | undefined;

type TicketType = {
  form?: FormFieldModel[];
  list?: AttendanceTicketModel[];
};

type OrderType = {
  form?: FormFieldModel[];
  list?: OrderModel[];
};

type MessageContextType = {
  pIdOmni: string;
  loading: boolean;
  messages?: MessageModel[];
  integrations?: IntegrationModel[];
  formFields?: FormFieldModel[];
  histories?: HistoricModel[];
  tickets?: TicketType;
  orders?: OrderType;
  question?: QuestionModel;
  attendance?: AttendanceModel;
  getMessages: (isReload?: boolean) => Promise<void>;
  addMessage: (key: number, value: MessageModel) => Promise<UndefinedMap>;
  editMessage: (key: number, value: MessageModel) => Promise<UndefinedMap>;
  removeMessage: (key: number) => Promise<UndefinedMap>;
  getFormDataByFields: () => FormData;
  updateFormFields: (form: FormFieldModel[]) => void;
  getTickets: (isReload?: boolean) => Promise<void>;
  getOrders: (isReload?: boolean) => Promise<void>;
  getQuestion: (isReload?: boolean) => Promise<void>;
  getPreviousQuestion: () => Promise<void>;
};

type MessageProviderType = PermissionProviderType & {
  pIdOmni: string;
  isMessage?: boolean;
  isVoice?: boolean;
};

const MessageContext = createContext({} as MessageContextType);

const allIntegrations = JSON.parse(ss.getItem(allIntegrationsKey) ?? "{}");
const allForms = JSON.parse(ss.getItem(allFormsKey) ?? "{}");
const allHistories = JSON.parse(ss.getItem(allHistoriesKey) ?? "{}");
const allMessages = JSON.parse(ss.getItem(allMsgsKey) ?? "{}");
const allTickets = JSON.parse(ss.getItem(allTicketsKey) ?? "{}");
const allOrders = JSON.parse(ss.getItem(allOrdersKey) ?? "{}");
const allQuestions = JSON.parse(ss.getItem(allQuestionsKey) ?? "{}");

const Provider = ({
  children,
  pIdOmni,
  isMessage,
  isVoice,
}: Omit<MessageProviderType, "hasPermission">) => {
  const [loading, setLoading] = useState(true);
  const [list, { set, add, edit, remove }] = useMap<number, MessageModel>();
  const [integrations, setIntegrations] = useState<IntegrationModel[]>();
  const [formFields, setFormFields] = useState<FormFieldModel[]>();
  const [histories, setHistories] = useState<HistoricModel[]>();
  const [tickets, setTickets] = useState<TicketType>();
  const [orders, setOrders] = useState<OrderType>();
  const [question, setQuestion] = useState<QuestionModel>();
  const { user } = useProfile();
  const { getAttendance } = useAttendance();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const attendance = useMemo(
    () => getAttendance(Number(pIdOmni)),
    [pIdOmni, getAttendance]
  );

  const hasAttendance = useMemo(() => !!attendance, [attendance]);

  const messages = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  useEffect(() => {
    if (pIdOmni) {
      setLoading(true);
      setIntegrations(undefined);
      setFormFields(undefined);
      setHistories(undefined);
      setTickets(undefined);
      setOrders(undefined);
      setQuestion(undefined);
    }
  }, [pIdOmni]);

  const getMessages = useCallback(
    async (isReload?: boolean) => {
      if (hasAttendance && isMessage && (isReload ?? loading))
        try {
          const result = AttendanceService.getMessagesIdSync({
            pIdOmni,
            pWasGet: true,
          });

          const current: MessageModel[] = allMessages[pIdOmni];
          const msgs = new Map<number, MessageModel>(
            current?.map((c) => [c.IdOmniMessage, c])
          );

          if (result.idReturnAPI > 0) {
            const list = result.omniMessageListOnLine
              ?.filter(({ IdOmniMessage }) => !msgs.has(IdOmniMessage))
              .map(({ IdOmniMessage }) => IdOmniMessage.toString());

            if (list.length > 0) {
              setLoading(true);
              await AttendanceService.getMessages({
                pIdOmni,
                pIdOmniMessage: list,
              }).then(({ data }) => {
                data.omniMessageListOnLine.forEach((msg) =>
                  msgs.set(msg.IdOmniMessage, msg)
                );
              });
            }
          } else warning({ description: t(aytyFormatError(result)) });

          set(msgs);
          allMessages[pIdOmni] = Array.from(msgs.values());
          ss.setItem(allMsgsKey, JSON.stringify(allMessages));
        } catch (e: any) {
          errorsResolver.defaultSyncError(e);
        } finally {
          setLoading(false);
        }
    },
    [
      hasAttendance,
      isMessage,
      pIdOmni,
      loading,
      errorsResolver,
      set,
      warning,
      t,
    ]
  );

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  useEffect(() => {
    const interval = setInterval(() => getMessages(true), tenSeconds);
    return () => clearInterval(interval);
  }, [getMessages]);

  const getIntegrations = useCallback(async () => {
    if (isVoice) {
      let current: IntegrationModel[] = allIntegrations[pIdOmni];

      if (!current)
        await AttendanceService.getIntegrations({ pIdOmni }).then(
          async ({ data }) => {
            if (data.idReturnAPI > 0) {
              current = data.omniIntegrationDataList;
              allIntegrations[pIdOmni] = data.omniIntegrationDataList;
            } else warning({ description: t(aytyFormatError(data)) });
          }
        );

      setIntegrations(current);
      ss.setItem(allIntegrationsKey, JSON.stringify(allForms));
    }
  }, [isVoice, pIdOmni, warning, t]);

  const getForms = useCallback(async () => {
    let current: FormFieldModel[] = allForms[pIdOmni];

    if (!current)
      await AttendanceService.getFormFields({ pIdOmni }).then(
        async ({ data }) => {
          if (data.idReturnAPI > 0) {
            current = data.bindListCustomer;
            allForms[pIdOmni] = data.bindListCustomer;
          } else warning({ description: t(aytyFormatError(data)) });
        }
      );

    setFormFields(current);
    ss.setItem(allFormsKey, JSON.stringify(allForms));
  }, [pIdOmni, warning, t]);

  const getHistories = useCallback(async () => {
    let current: HistoricModel[] = allHistories[pIdOmni];

    if (!current)
      await AttendanceService.getHistories({ pIdOmni }).then(
        async ({ data }) => {
          if (data.idReturnAPI > 0) {
            current = data.omniHistoryMessageList;
            allHistories[pIdOmni] = data.omniHistoryMessageList;
          } else warning({ description: t(aytyFormatError(data)) });
        }
      );

    setHistories(current);
    ss.setItem(allHistoriesKey, JSON.stringify(allHistories));
  }, [pIdOmni, warning, t]);

  const getTickets = useCallback(
    async (isReload?: boolean) => {
      let current: TicketType = allTickets[pIdOmni] ?? {};

      if (isReload || !current.list)
        await AttendanceService.getTicketList({ pIdOmni }).then(
          async ({ data }) => {
            if (data.idReturnAPI > 0) current.list = data.ticketList;
            else warning({ description: t(aytyFormatError(data)) });
          }
        );

      if (!current.form)
        await AttendanceService.getFormTicket({ pIdOmni }).then(
          async ({ data }) => {
            if (data.idReturnAPI > 0) current.form = data.bindListTicket;
            else warning({ description: t(aytyFormatError(data)) });
          }
        );

      allTickets[pIdOmni] = current;

      setTickets({ ...current });
      ss.setItem(allTicketsKey, JSON.stringify(allTickets));
    },
    [pIdOmni, warning, t]
  );

  const getOrders = useCallback(
    async (isReload?: boolean) => {
      let current: OrderType = allOrders[pIdOmni] ?? {};

      if (isReload || !current.list)
        await AttendanceService.getOrderList({ pIdOmni }).then(
          async ({ data }) => {
            if (data.idReturnAPI > 0) current.list = data.orderList;
            else warning({ description: t(aytyFormatError(data)) });
          }
        );

      if (!current.form)
        await AttendanceService.getFormOrder({ pIdOmni }).then(
          async ({ data }) => {
            if (data.idReturnAPI > 0) current.form = data.bindListOrder;
            else warning({ description: t(aytyFormatError(data)) });
          }
        );

      allOrders[pIdOmni] = current;

      setOrders({ ...current });
      ss.setItem(allOrdersKey, JSON.stringify(allOrders));
    },
    [pIdOmni, warning, t]
  );

  const getQuestion = useCallback(
    async (isReload?: boolean) => {
      let current: QuestionModel = allQuestions[pIdOmni];

      if (isReload || !current)
        await AttendanceService.getQuestion({ pIdOmni }).then(
          async ({ data }) => {
            if (data.idReturnAPI > 0) {
              const { idReturnAPI, deReturnAPI, dtServer, ...args } = data;
              current = args;
              allQuestions[pIdOmni] = args;
            } else warning({ description: t(aytyFormatError(data)) });
          }
        );

      setQuestion(current);
      ss.setItem(allQuestionsKey, JSON.stringify(allQuestions));
    },
    [pIdOmni, warning, t]
  );

  const getPreviousQuestion = useCallback(async () => {
    let current: QuestionModel = allQuestions[pIdOmni];

    await AttendanceService.previousQuestion({ pIdOmni }).then(
      async ({ data }) => {
        if (data.idReturnAPI > 0) {
          const { idReturnAPI, deReturnAPI, dtServer, ...args } = data;
          current = args;
          allQuestions[pIdOmni] = args;
        } else warning({ description: t(aytyFormatError(data)) });
      }
    );

    setQuestion(current);
    ss.setItem(allQuestionsKey, JSON.stringify(allQuestions));
  }, [pIdOmni, warning, t]);

  const getLists = useCallback(async () => {
    if (hasAttendance) {
      await Promise.all([getIntegrations(), getForms(), getHistories()]).catch(
        errorsResolver.defaultError
      );

      if (user?.hasOrderModule)
        await getOrders().catch(errorsResolver.defaultError);

      if (user?.hasQuestionnaireModule)
        await getQuestion().catch(errorsResolver.defaultError);

      if (user?.hasTicketModule)
        await getTickets().catch(errorsResolver.defaultError);
    }
  }, [
    user,
    hasAttendance,
    errorsResolver,
    getIntegrations,
    getForms,
    getHistories,
    getTickets,
    getOrders,
    getQuestion,
  ]);

  useEffect(() => {
    getLists();
  }, [getLists]);

  const getFormDataByFields = useCallback(() => {
    const formData = new FormData();

    formFields?.forEach((field) => {
      formData.append(field.NmControl, field.VlProperty?.toString());
    });

    return formData;
  }, [formFields]);

  const updateFormFields = useCallback(
    (form: FormFieldModel[]) => {
      allForms[pIdOmni] = form;
      setFormFields([...form]);
      ss.setItem(allFormsKey, JSON.stringify(allForms));
    },
    [pIdOmni]
  );

  return (
    <MessageContext.Provider
      value={{
        pIdOmni,
        loading,
        messages,
        integrations,
        formFields,
        histories,
        tickets,
        orders,
        question,
        attendance,
        getMessages,
        addMessage: add,
        editMessage: edit,
        removeMessage: remove,
        getFormDataByFields,
        updateFormFields,
        getTickets,
        getOrders,
        getQuestion,
        getPreviousQuestion,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const MessageProvider = ({
  hasPermission = true,
  ...args
}: MessageProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useMessage = () => useContext(MessageContext);
